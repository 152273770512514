import React, { useRef, useState } from "react";
import logo1 from "../../assets/images/logo1.png";
import logo2 from "../../assets/images/logo2.png";
import logo3 from "../../assets/images/logo3.png";
import logo4 from "../../assets/images/logo4.png";
import logo5 from "../../assets/images/logo5.png";
import logo6 from "../../assets/images/logo6.png";
import logo7 from "../../assets/images/logo7.png";
import logo8 from "../../assets/images/logo8.png";
import logo9 from "../../assets/images/logo9.png";
import logo10 from "../../assets/images/logo10.png";
import logo11 from "../../assets/images/logo11.png";
import logo12 from "../../assets/images/logo12.png";
import logo13 from "../../assets/images/logo13.png";
import logo14 from "../../assets/images/logo14.png";
import logo15 from "../../assets/images/logo15.png";
import logo16 from "../../assets/images/logo16.png";
import logo17 from "../../assets/images/logo17.png";
import logo18 from "../../assets/images/logo18.png";
import logo19 from "../../assets/images/logo19.png";
import logo20 from "../../assets/images/logo20.png";
import logo21 from "../../assets/images/logo21.png";


// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination } from "swiper";

const Industriesserve = () => {
  return (
    <>
      <div className="Industriesserve">
            <div className="container">
            <div className="Industriesserve-wrapper align-items-center">
         <div className="Industriesserve-slider">
          <div className="swiper-Industriesserve">
          <Swiper
                          slidesPerView={1}
                      spaceBetween={5}
                       pagination={{
                    clickable: true,
                      }}
                    navigation={true}
                       loop={'true'}
                     autoplay={{
                      delay: 5000,
                      disableOnInteraction: true,
                      
                                }}
                                modules={[Autoplay]}
                     breakpoints={{
                        320: {
                       slidesPerView: 1,
                       spaceBetween: 20,
                         },
                         640: {
                       slidesPerView: 3,
                       spaceBetween: 20,
                         },
                         768: {
                        slidesPerView: 3,
                       spaceBetween: 20,
                          },
                          1024: {
                    slidesPerView: 6,
                        spaceBetween: 50,
                           },
                              }}
              
                    className="mySwiper"
                               >
        <SwiperSlide><img src={logo2} alt="" className="clientlogo1" /></SwiperSlide>
        <SwiperSlide><img src={logo4} alt="" className="clientlogo1" /></SwiperSlide>
        <SwiperSlide><img src={logo5} alt="" className="clientlogo1" /></SwiperSlide>
        <SwiperSlide><img src={logo6} alt="" className="clientlogo1" /></SwiperSlide>
        <SwiperSlide><img src={logo7} alt="" className="clientlogo1" /></SwiperSlide>
        <SwiperSlide><img src={logo8} alt="" className="clientlogo1" /></SwiperSlide>
        <SwiperSlide><img src={logo9} alt="" className="clientlogo1" /></SwiperSlide>
        <SwiperSlide><img src={logo10} alt="" className="clientlogo1" /></SwiperSlide>
        <SwiperSlide><img src={logo11} alt="" className="clientlogo1" /></SwiperSlide>
        <SwiperSlide><img src={logo12} alt="" className="clientlogo1" /></SwiperSlide>
        <SwiperSlide><img src={logo13} alt="" className="clientlogo1" /></SwiperSlide>
        <SwiperSlide><img src={logo14} alt="" className="clientlogo1" /></SwiperSlide>
        <SwiperSlide><img src={logo15} alt="" className="clientlogo1" /></SwiperSlide>
        <SwiperSlide><img src={logo16} alt="" className="clientlogo1" /></SwiperSlide>
        <SwiperSlide><img src={logo17} alt="" className="clientlogo1" /></SwiperSlide>
        <SwiperSlide><img src={logo18} alt="" className="clientlogo1" /></SwiperSlide>
        <SwiperSlide><img src={logo19} alt="" className="clientlogo1" /></SwiperSlide>
        <SwiperSlide><img src={logo20} alt="" className="clientlogo1" /></SwiperSlide>
        <SwiperSlide><img src={logo21} alt="" className="clientlogo1" /></SwiperSlide>


      </Swiper>
                       
                        </div>
                    </div>
                </div>
            </div>
        </div>
     
    </>
  );
}
export default Industriesserve;