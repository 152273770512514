
import React from "react";

const Servicepagebanner = () => {
    return (
        <>
        <section className="Servicepagebanner">
        <div className="container">
            <div className="row">
                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                    <h1>Services <span className='mycolor'> we provide</span></h1>
                </div>
            </div>
        </div>
        </section>
        <section className="abtbottom">
        <div className="container">
            <div className="row text-center">
                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                 <div className="abtbotmbox">
                    <ul className="abtlist">
                    <li className="firstlist"><a href="#Hiring"> Hiring</a> </li>
                    <li className="firstlist"><a href="#Onboarding">Onboarding </a> </li>
                    <li className="firstlist"><a href="#Operations">Operations</a> </li>
                   <li className="firstlist"><a href="#ER">ER</a></li>
                    <li className="firstlist" ><a href="#Engagement">Engagement</a></li>
                    <li className="firstlist" ><a href="#Payroll">Payroll </a></li>
                    <li className="firstlist" ><a href="#Attendance">Attendance</a></li>
                    <li className="firstlist" ><a href="#Counseling">Counseling</a></li>
                     <li><a href="#Compliance">Compliance</a></li>
                    </ul>
                 </div>
                </div>
            </div>
        </div>
        </section>
        
        </>
    );
}
 
export default Servicepagebanner;