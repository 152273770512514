import React from "react";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';

import safe from "../../assets/images/safe.png";
import education from "../../assets/images/education.png";
import realestate from "../../assets/images/realestate.png";
import powerplant from "../../assets/images/powerplant.png";
import budget from "../../assets/images/budget.png";
import storefront from "../../assets/images/storefront.png";




const ClientsFour = () => {
    const navigationPrevRef = React.useRef(null)
    const navigationNextRef = React.useRef(null)
    return (
        <div className="insd-section" >
            <div className="container">
              <div className="section-wrapper overflow-hidden">
                    <div className="insd-slider">
                        <div className="swiper-wrapper">
                        <Swiper
                          slidesPerView={1}
                      spaceBetween={5}
                       pagination={{
                    clickable: true,
                      }}
   
               
                       loop={'true'}
                     autoplay={{
                      delay: 5000,
                      disableOnInteraction: false,
                                }}
                                modules={[Autoplay]}
                     breakpoints={{
                        320: {
                       slidesPerView: 2,
                       spaceBetween: 10,
                         },
                         640: {
                       slidesPerView: 2,
                       spaceBetween: 20,
                         },
                         768: {
                        slidesPerView: 4,
                       spaceBetween: 20,
                          },
                          1024: {
                    slidesPerView: 5,
                        spaceBetween: 20,
                           },
                           1200: {
                    slidesPerView: 5,
                        spaceBetween: 20,
                           },
                           1400: {
                    slidesPerView: 6,
                        spaceBetween: 20,
                           },
                           1600: {
                    slidesPerView: 6,
                        spaceBetween: 20,
                           },
                              }}
             
                    className="mySwiper"
                               >

                                <SwiperSlide>
                                <div className="client-thumb4">
                                <img src={safe} alt="" className="industimg" />
                                <h4>Healthcare</h4>
                                </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                <div className="client-thumb4">
                                <img src={education} alt="" className="industimg" />
                                <h4>Education</h4>
                                </div>
                                 </SwiperSlide>
                                <SwiperSlide>
                                <div className="client-thumb4">
                                <img src={realestate} alt="" className="industimg" />
                                <h4>Real Estate</h4>
                                </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                <div className="client-thumb4">
                                <img src={powerplant} alt="" className="industimg" />
                                <h4>Manufacturing </h4>
                                </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                <div className="client-thumb4">
                                <img src={budget} alt="" className="industimg" />
                                <h4> Hospitality </h4>
                                </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                <div className="client-thumb4">
                                <img src={storefront} alt="" className="industimg" />
                                <h4>Retail</h4>
                                </div>
                                </SwiperSlide>
                                <div ref={navigationPrevRef} />
      <div ref={navigationNextRef} />
                            </Swiper>
                         
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default ClientsFour;