
const title = "Our Popular Tags";


const tagsList = [
    {
        link: '#',
        text: 'HR outsourcing',
    },
    {
        link: '#',
        text: 'Recruitment',
    },
    {
        link: '#',
        text: 'HR functions',
    },
    {
        link: '#',
        text: 'HRIS',
    },
    {
        link: '#',
        text: 'Employment laws',
    },
    {
        link: '#',
        text: 'Regulations ',
    },
    {
        link: '#',
        text: 'Expertise ',
    },
    {
        link: '#',
        text: 'Hiring',
    },
    {
        link: '#',
        text: 'Payroll',
    },
]

const Tags = () => {
    return (
        <div className="widget widget-tags">
            <div className="widget-header">
                <h5 className="title">{title}</h5>
            </div>
            <ul className="widget-wrapper">
                {tagsList.map((val, i) => (
                    <li key={i}><a href={val.link}>{val.text}</a></li>
                ))}
            </ul>
        </div>
    );
}
 
export default Tags;