
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from "swiper";

const subTitle = "Don’t Miss the Day";


const clientList = [
    {
        imgUrl: 'assets/images/clients/client1.png',
        imgAlt: 'education thumb rajibraj91 rajibraj',
        desc: 'My firm was doing great until our HR left the company at a critical time. Then someone advised me to outsource the HR services. I thought, "why would anyone do that?" But the amount of savings I have now have answered me very well. A big thumbs up to team HR essentia.',
        name: 'Namrata Singhania',
        degi: 'Manager',
    },
    {
        imgUrl: 'assets/images/clients/client2.png',
        imgAlt: 'education thumb rajibraj91 rajibraj',
        desc: 'How could we have an HR when we did not even have a physical office? This is why we hired HR essentia, and all of our work is going well. They even hired the perfect candidates for our vacancies as per our specifications. I am thankful to the whole team of HR Essentia.',
        name: 'Karan Malhotra',
        degi: 'CEO',
    },
    {
        imgUrl: 'assets/images/clients/client3.png',
        imgAlt: 'education thumb rajibraj91 rajibraj',
        desc: 'I was frustrated with a load of every other task from paying salaries to remembering their birthdays. It is not easy to build a startup. Thankfully I got to know about HR essentia and they helped us in managing everything. Their team is great at what they do! Half of my stress is gone!',
        name: 'Vivek Malik',
        degi: 'Manager',
    },
]


const Clients = () => {
    return (
        <div className="clients-section" id="Reviews">
            <div className="container">
                <div className="section-header text-center">
                 <h2>Feedbacks <span className='mycolor'> matter!</span>  </h2>
                    <p id="booterheader">We keep customer satisfaction ratings high, demonstrating our commitment to providing <br />excellent service. Here is what our clients say about us: </p>
                </div>
                <div className="section-wrapper overflow-hidden">
                    <div className="clients-slider">
                        <div className="swiper-wrapper">
                        <Swiper
                          slidesPerView={1}
                      spaceBetween={5}
                       pagination={{
                    clickable: true,
                      }}
                    navigation={true}
                       loop={'true'}
                     autoplay={{
                      delay: 5000,
                      disableOnInteraction: false,
                                }}
                     breakpoints={{
                        320: {
                       slidesPerView: 1,
                       spaceBetween: 10,
                         },
                         640: {
                       slidesPerView: 2,
                       spaceBetween: 20,
                         },
                         768: {
                        slidesPerView: 2,
                       spaceBetween: 20,
                          },
                          1024: {
                    slidesPerView: 3,
                        spaceBetween: 20,
                           },
                           1200: {
                    slidesPerView: 3,
                        spaceBetween: 20,
                           },
                              }}
                //    modules={[Pagination]}
                    className="mySwiper"
                               >
	  
                                {clientList.map((val, i) => (
                                    <SwiperSlide key={i}>
                                    <div className="client-thumb3">
                                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                </div>
                                        <div className="client-item3">
                                        
                                            <div className="client-inner3">
                                               <div className="client-info3">
                                                        <h6 className="client-name3">{val.name}</h6>
                                                        <span className="client-degi3">{val.degi}</span>
                                                    </div>
                                                <div className="client-content3">
                                                    <p className="test-review">{val.desc}</p>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                                
                            </Swiper>
                       
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Clients;