
import React, { useState } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

const Jobseekermodal = () => {
    const [open, setOpen] = useState(false);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);
    return (
   <>
   <div>
   <button className='modalbtn' onClick={onOpenModal}>Submit Your Details</button>
   <Modal  open={open} onClose={onCloseModal} center>
   <div className="seekermodal">
     <h4>Find out a place that <span className='mycolor'>feels good </span></h4>
     <p>Fill out this form with your details and one of our experts will contact you.</p>
     <form action="jobseer.php"
                     method="post"
                    encType="multipart/form-data"
                    id="jobdetil">
                  <div className="row">
                    <div className="col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12">
                    <input type="text" name="name" placeholder="Your Name" className="reg-input" required />
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12" >
                    <input type="text" name="phone" placeholder="Your Phone" className="reg-input" required />
                    </div>
                  </div>
                  <div className="row">
                  <div className="col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12">
                  <input type="email" name="email" placeholder="Your Email" className="reg-input" required />
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12">
                    <input type="text" name="location" placeholder="Your Location" className="reg-input" required />
                                        </div>
                     </div>
                    <div className='row'>
                    <div className="col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12">
                    <input type="text" name="jobprofile" placeholder="Job Profile" className="reg-input" required />
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12">
                    <input type="text" name="industrytype" placeholder="Industry Type" className="reg-input" required />
                    </div>
                    </div>
                    <div className='row'>
                    <div className="col-xxl-12 col-xl-12 col-md-12 col-lg-12 col-12" >
                    <select name="experience" id="experience">
                    <option>Experience</option>
                    <option value="fresher" name="fresher">Fresher</option>
                    <option value="experienced" name="experienced">Experienced</option>
                    </select>
                   <label class="fieldlabels">Upload CV</label>
                <input type="file" name="file" multiple="" />
                    </div>
                    </div>
                 <button className="lab-btn" type="submit"><span>Submit</span></button>
                 </form>
                 </div>
   </Modal>
 </div>

   </>
    );
}
 
export default Jobseekermodal;