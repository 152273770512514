
import React from "react";
import hrteam from "../../assets/images/hrteam.webp";
import visi from "../../assets/images/visi.png";
import Sponsor from "./sponsor";
import cert from "../../assets/images/cert.webp";


const Aboutsections = () => {
    return (
        <>
        <section className="abtbnner">
        <div className="container">
            <div className="row">
                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                    <h1>Slash Expenses with Our <br /><span className='mycolor'> HR Solutions</span></h1>
                </div>
            </div>
        </div>
        </section>
        <section className="abtbottom">
        <div className="container">
            <div className="row text-center">
                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                 <div className="abtbotmbox">
                    <ul className="abtlist">
                    <li className="firstlist"><a href="#Overview"> Company Overview</a> </li>
                    <li className="firstlist"><a href="#Mission">Mission, Vision & Values </a> </li>
                    <li className="firstlist"><a href="#Client">Our Clients </a> </li>
                   <li className="firstlist"><a href="#Certificates">Achievements & Certificates</a></li>
                    <li className="firstlist" ><a href="#Stats">Growth Stats </a></li>
                  <li><a href="#Reviews">Reviews</a></li>
                    </ul>
                 </div>
                </div>
            </div>
        </div>
        </section>
        <section className="abtsec1" id="Overview">
        <div className="container">
            <div className="row">
                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                    <p className="working" >Our team of seasoned professionals boasts decades of experience in HR departments. We'll analyze<br /> your company's size and present you with a customized plan that fits like a glove. </p>
                </div>
            </div>
            <div className="row text-center" id="tean">
        <div className="col-xxl-5 col-xl-5 col-md-5 col-lg-5 col-12" id="tean-left">
         <img src={hrteam} alt="" className="hrteam" />
        </div>
        <div className="col-xxl-7 col-xl-7 col-md-7 col-lg-7 col-12" id="tean-right">
        <div className="abtcontent">
            <h2>Who are <span className='mycolor'>we?</span></h2>
            <p>At HR Essentia, we offer comprehensive services to help businesses streamline their HR functions and improve their overall operations. </p>
            <p>Our years of experience have sharpened our sense of authority when identifying the perfect fit for your organization’s unique working style. Trust us to provide recruiters who will always find the best candidate to fill your vacancies. 
            </p>
            <p>With us, you need not worry about your human resources; we will take care of everything for you. Don't settle for less- Trust HR Essentia for all your HR needs. </p>
<div className="mybtn">
<a class="Quote" href="/contactus">Get A Quote</a>
</div>
        </div>
        </div>
        </div>
            </div>
          </section>
    <section className="abtsec2" id="Mission">
<div className="container">
      <div className="row">
    <div className="col-xxl-6 col-xl-6 col-md-12 col-lg-6 col-12">
    <div className="Expenses">
        <h2>The Vision To Make <span className='mycolor'>Working Better </span></h2>
        <p>Our team of recruiters has years of experience in this industry. Our experts ensure that the candidates we choose for your firm are an ideal fit for your company and have ethical values that benefit your firm. We understand your business requirements and provide you with the best solution accordingly. </p>
        <p> We at HR Essentia comprehend that HR management is not just a science but an art of understanding human behavior in a company, so we provide custom-built solutions with an expandable candidate network. </p>
     
                </div>
    </div>
    <div className="col-xxl-6 col-xl-6 col-md-12 col-lg-6 col-12" id="wrong">
    <div class="abtbox1">
    <span className="abtvisi"><i class="fa fa-eye" aria-hidden="true"></i></span>
    <div className="one">
    <h5>Our Mission</h5>
    <span>
     <p>Businesses often struggle to find suitable talent for their company and hire the wrong ones. Our mission is to help businesses get what they need.</p>
     </span>
    </div>
    </div>
    <div class="abtbox2">
    <span className="abtvisi"><i class="fa fa-bullseye" aria-hidden="true"></i></span>
    <div className="one">
    <h6>Our Vision </h6>
    <span>
     <p>Every organization requires to handle its Human resources—our vision is to match startups with hardworking and ambitious talent. </p>
     </span>
    </div>
    </div>
    <div class="abtbox3">
    <span className="abtvisi"><i class="fa fa-balance-scale" aria-hidden="true"></i></span>
    <div className="one">
    <h5>Our Ethics</h5>
    <span>
     <p>We believe that a good organization runs on good ethics. Our team is filled with values of honesty, integrity, and transparency.</p>
     </span>
    </div>
    </div>
    </div>
    </div>
</div>
    </section>
    <section className="abtsec3" id="Client">
<div className="container">
    <div className="row">
        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
            <h2>Businesses we <span className='mycolor'>are serving</span></h2>
            <p id="booterheader">At HR Essentia, we take pride in serving a diverse range of clients from various industries.<br /> Here are some of the clients we have served:  </p>
<Sponsor />
        </div>
    </div>
    <div id="Certificates"></div>
</div>
    </section>


   
    {/* <section className="abtsec4" >
        <div className="container">
            <div className="row">
                <div className="col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12">
                    <h2>Achievements & <span className='mycolor'>Certificates</span></h2>
                    <h6>Accomplishments that demonstrate our success </h6>
                    <p>At HR Essentia, we are committed to providing top-quality services to our clients, and our achievements and certificates reflect this commitment.</p>
                    <p>We constantly strive to improve our skills and knowledge to provide the best solutions. With us, you need not worry about: </p>
                    <ul class="lab-ul">
        <li><i class="icofont-tick-mark"></i>Hiring the right talent with desired skills</li>
        <li><i class="icofont-tick-mark"></i>Taking care of your relations with employees</li>
        <li><i class="icofont-tick-mark"></i>Organizing monthly payrolls by tracking all the records</li>
        <li><i class="icofont-tick-mark"></i>Considering women's safety in the workplace</li>
        <li><i class="icofont-tick-mark"></i>Handling your HR operations (KYC, record-keeping, documentation, etc.) </li>
        </ul>
        <br />
        <div className="mybtn">
<a class="Quote" href="/contactus">Get A Quote</a>
</div>
                </div>
                <div className="col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12">
                <img src={cert} alt="" className="cert" />
                </div>
            </div>
        </div>
    </section> */}
  
        </>
    );
}
 
export default Aboutsections;