
import { Autoplay} from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';


const subTitle = "Don't Believe Us?";
const title = "See What People Say";


const clientSliderList = [
    {
        imgUrl: 'assets/images/clients/client1.png',
        imgAlt: 'education thumb rajibraj91 rajibraj',
        desc: 'My firm was doing great until our HR left the company at a critical time. Then someone advised me to outsource the HR services. I thought, "why would anyone do that?" But the amount of savings I have now have answered me very well. A big thumbs up to team HR essentia.',
        name: 'Namrata Singhania',
        degi: 'Manager',
    },
    {
        imgUrl: 'assets/images/clients/client2.png',
        imgAlt: 'education thumb rajibraj91 rajibraj',
        desc: 'How could we have an HR when we did not even have a physical office? This is why we hired HR essentia, and all of our work is going well. They even hired the perfect candidates for our vacancies as per our specifications. I am thankful to the whole team of HR Essentia.',
        name: 'Karan Malhotra',
        degi: 'CEO',
    },
    {
        imgUrl: 'assets/images/clients/client3.png',
        imgAlt: 'education thumb rajibraj91 rajibraj',
        desc: 'I was frustrated with a load of every other task from paying salaries to remembering their birthdays. It is not easy to build a startup. Thankfully I got to know about HR essentia and they helped us in managing everything. Their team is great at what they do! Half of my stress is gone!',
        name: 'Vivek Malik',
        degi: 'Manager',
    },
]

const ClientsThree = () => {
    return (
        <div className="clients-section style-3 padding-tb">
            <div className="container">
                <div className="section-header text-center">
                    <span className="subtitle yellow-color">{subTitle}</span>
                    <h2 className="title">{title}</h2>
                                </div>
                <div className="section-wrapper">
                    <div className="clients-slider2 overflow-hidden">
                        <Swiper
                            loop={'true'}
                            autoplay={{
                                delay: 4000,
                                disableOnInteraction: true,
                            }}
                         
                            // navigation={{
                            //     prevEl: '.clients-slider2-prev',
                            //     nextEl: '.clients-slider2-next',
                            // }}
                            
                            modules={[Autoplay]}
                           
                        >
                            {clientSliderList.map((val, i) => (
                                <SwiperSlide key={i}>
                                    <div className="client-item text-center">
                                        <div className="client-inner text-center">
                                            <div className="client-thumb text-center">
                                                <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                <div className="quote">
                                                    <i className={val.iconNane}></i>
                                                </div>
                                            </div>
                                            <div className="client-content text-center">
                                                <p>{val.desc}</p>
                                                <div className="client-info">
                                                    <h6 className="client-name">{val.name}</h6>
                                                    <span className="client-degi">{val.degi}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        {/* <div className="clients-slider-nav clients-slider2-next"><i className="icofont-double-left"></i></div>
                        <div className="clients-slider-nav clients-slider2-prev"><i className="icofont-double-right"></i></div> */}
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default ClientsThree;