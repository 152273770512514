import React from "react";
import smartgirl from "../../assets/images/smartgirl.png";


const subTitle = "Online education";
const title = <h2 className="title"><span className="d-lg-block">Learn The</span> Skills You Need <span className="d-lg-block">To Succeed</span></h2>;
const desc = "Free online courses from the world’s Leading experts. join 18+ million Learners today.";


// const catagoryList = [
//     {
//         name: 'Figma',
//         link: '#',
//     },
//     {
//         name: 'Adobe XD',
//         link: '#',
//     },
//     {
//         name: 'illustration',
//         link: '#',
//     },
//     {
//         name: 'Photoshop',
//         link: '#',
//     },
// ]


const shapeList = [
    {
        name: 'Compliance',
        imgUrl: 'assets/images/skill/icon/banpic1.png',
        imgAlt: 'Compliance',
        link: '#',
       className: 'ccl-shape shape-1',
    },
    {
        name: 'Payroll',
        imgUrl: 'assets/images/skill/icon/banpic2.png',
        imgAlt: 'Payroll',
        link: '#',
        className: 'ccl-shape shape-2',
    },
    // {
    //     name: '89% Successful Students',
    //     link: '#',
    //     className: 'ccl-shape shape-3',
    // },
    {
        name: 'Hiring',
        imgUrl: 'assets/images/skill/icon/banpic3.png',
        imgAlt: 'Hiring',
        link: '#',
        className: 'ccl-shape shape-4',
    },
    // {
    //     name: '36+ Languages',
    //     link: '#',
    //     className: 'ccl-shape shape-5',
    // },
]

const Banner = () => {
    return (
        <section className="banner-section mybannerupdated">
            <div className="container">
                <div className="section-wrapper">
                    <div className="row align-items-center">
                    <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
    <div className='bnrtext1'>
    <h3
     className="wow fadeInUp"
              data-wow-delay="0.6s"
              data-wow-duration="1s"
              style={{
                visibility: "visible",
                animationDuration: "1s",
                animationDelay: "0.6s",
                animationName: "fadeInUp"
              }}
    >We Have <span className='mycolor'>10,000+</span> Live Jobs</h3>
<h1
 className="wow fadeInUp"
              data-wow-delay="0.9s"
              data-wow-duration="1s"
              style={{
                visibility: "visible",
                animationDuration: "1s",
                animationDelay: "0.9s",
                animationName: "fadeInUp"
              }}><span className='mycolor'>Focus on Essentials, </span>we are here for the rest</h1>
<p
className="wow fadeInUp"
              data-wow-delay="0.9s"
              data-wow-duration="1s"
              style={{
                visibility: "visible",
                animationDuration: "1s",
                animationDelay: "0.9s",
                animationName: "fadeInUp"
              }}>
              <span className='mycolor'>Innovative outsourcing agency </span> for startups & large firms to give them a helping hand in HR operations.</p>
        <form 
          className="wow fadeInUp"
              data-wow-delay="0.12s"
              data-wow-duration="1s"
              style={{
                visibility: "visible",
                animationDuration: "1s",
                animationDelay: "0.12s",
                animationName: "fadeInUp"
              }}
              action="newsletter.php"
                     method="post"
                    encType="multipart/form-data"
                    id="myForm">
  <input className='bnnrfrm' name='email' type="email" placeholder="Talk to the “HR autopilot”" required />
  <button type="submit">Get A Quote</button>
  </form>
  </div>
</div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="banner-thumb">
                           <img src={smartgirl} alt=""  className="smartgirl" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="all-shapes"></div>
            <div className="cbs-content-list">
                <ul className="lab-ul" id="bnrshpaeimg">
                    {shapeList.map((val, i) => (
                        <li className={val.className} key={i}><a href={val.link}> <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />{val.name}{val.icon}</a></li>
                    ))}
                </ul>
            </div>
        </section>
    );
}
 
export default Banner;