import { Fragment } from "react";
import Footer1 from "../component/layout/footer";
import Header from "../component/layout/header";
;

const Privacypolicy = () => {
    return (
        <Fragment>
            <Header />
           <section className="Privacypolicy">
            <div className="container">
                <div className="row">
                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                        <div className="Privacypolicyheader">
                            <h1 className="Privacy">Privacy <span className='mycolor'> Policy</span></h1>
                        </div>
                    </div>
                </div>
            </div>
           </section>
           <section className="Privacypolicy2">
            <div className="container">
                <div className="row">
                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                        <div className="Privacypolicyheader">
                            <h2 className="Privacy">Privacy <span className='mycolor'> Policy</span></h2>
                            <p>This Privacy Policy explains how HR Essentia collects, uses, and protects the personal information of its users. By using our website, you agree to collecting and using your personal information per this Privacy Policy. If you disagree with our privacy policy, you should stop using our website now.</p>
                            <h6>Collection of Personal Information</h6>
                            <p>We may collect personal information such as your name, email address, and other contact information when you sign up for our services when you use certain features of our website. We may also collect additional information, such as your location and preferences, to improve your experience on our website.</p>
                            <h6>Use of Personal Information</h6>
                            <p>We use your personal information to provide personalized services, improve the functionality of our website, and for marketing and promotional purposes. We may also use your personal information to respond to inquiries, resolve disputes, or enforce our agreements.</p>
                            <h6>Protection of Personal Information</h6>
                            <p>We take protecting your personal information seriously and have implemented appropriate security measures to protect your information from unauthorized access, alteration, or disclosure. We regularly review and update these measures to ensure the security of your personal information.</p>
                            <h6>Sharing of Personal Information</h6>
                            <p>We may share your personal information with third-party service providers, such as data analytics and marketing partners, to provide better services and improve your experience on our website. We will only share your personal information with third-party service providers bound by confidentiality obligations and who use your information only to provide services on our behalf.</p>
                            <h6>Access to Personal Information</h6>
                            <p>You have the right to access our personal information and request that we correct or delete it. You may also withdraw your consent for collecting and using your personal information at any time by contacting us.</p>
                            <h6>Changes to Privacy Policy</h6>
                            <p>We may change this Privacy Policy occasionally and notify you of any significant changes by posting a notice on our website. Your continued use of our website after any changes to this policy indicates your agreement with the new terms.</p>
                            <h6>Contact Us</h6>
                            <p>If you have any questions or concerns about this Privacy Policy, please contact us by [contact information].</p>
                           <h5>Disclaimer</h5>
                           <p>This Privacy Policy applies only to the personal information collected by HR Essentia through its website. It does not apply to information collected by third-party websites linked to our website. We are not responsible for any third-party website's privacy practices.</p>
                           <p>The laws of [jurisdiction] govern this Privacy Policy.</p>
<p>By using our website, you consent to the collection, use, and protection of your personal information in accordance with this Privacy Policy.
</p>
                        </div>
                    </div>
                </div>
            </div>
           </section>
         <Footer1 />
        </Fragment>
    );
}
 
export default Privacypolicy;