import React from 'react';
import Smilinggirl from "../../assets/images/Smilinggirl.png";


const Mybanner = () => {
 
  return (
  <>
  <section className='mybanner'>
  <div className='container'>
    <div className='row'>
    <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
    <div className='bnrtext'>
    <h3
     className="wow fadeInUp"
              data-wow-delay="0.6s"
              data-wow-duration="1s"
              style={{
                visibility: "visible",
                animationDuration: "1s",
                animationDelay: "0.6s",
                animationName: "fadeInUp"
              }}
    >We Have <span className='mycolor'>10,000+</span> Live Jobs</h3>
<h1
 className="wow fadeInUp"
              data-wow-delay="0.9s"
              data-wow-duration="1s"
              style={{
                visibility: "visible",
                animationDuration: "1s",
                animationDelay: "0.9s",
                animationName: "fadeInUp"
              }}><span className='mycolor'>Focus on Essentials, </span>we are here for the rest</h1>
<p
className="wow fadeInUp"
              data-wow-delay="0.9s"
              data-wow-duration="1s"
              style={{
                visibility: "visible",
                animationDuration: "1s",
                animationDelay: "0.9s",
                animationName: "fadeInUp"
              }}
><span className='mycolor'>Innovative outsourcing agency </span> for startups & large firms to give them a helping hand in HR operations.</p>

<form 
 className="wow fadeInUp"
              data-wow-delay="0.12s"
              data-wow-duration="1s"
              style={{
                visibility: "visible",
                animationDuration: "1s",
                animationDelay: "0.12s",
                animationName: "fadeInUp"
              }}
              action="newsletter.php"
                     method="post"
                    encType="multipart/form-data"
                    id="myForm">
  <input className='bnnrfrm' name='email' type="email" placeholder="Talk to the “HR autopilot”" required />
  <button type="submit">Get A Quote</button>
  </form>
  </div>
</div>
<div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
<img 
 className="wow fadeInUp"
              data-wow-delay="0.6s"
              data-wow-duration="1s"
              style={{
                visibility: "visible",
                animationDuration: "1s",
                animationDelay: "0.6s",
                animationName: "fadeInUp"
              }}
src={Smilinggirl} alt="" id='Smilinggirl' />
</div>
    </div>
   </div>
  </section>

  </>  )
}

export default Mybanner