import { Fragment } from "react";
import Footer1 from "../component/layout/footer";
import Header from "../component/layout/header";
import Aboutcomponent from "../component/section/aboutcomponent";
import Aboutsections from "../component/section/aboutsections";
import Clients from "../component/section/clients";

import {Helmet} from "react-helmet";


const AboutUs = () => {
    return (
        <Fragment>
            <Header />
<Helmet>
<title>Best HR Services Providers in Mohali, Chandigarh</title>
<meta name="description" content="Are you searching for HR Services for your startup/company? HR Essentia can handle your human resource in the most effective and affordable ways." />
<link rel="canonical" href="https://hressentia.com/aboutus" />
</Helmet>
        <Aboutsections />
       <Aboutcomponent />
       <Clients />

       <Footer1 />
       </Fragment>
    );
}
 
export default AboutUs;