import { Fragment } from "react";
import Footer1 from "../component/layout/footer";
import Header from "../component/layout/header";

const Termsandconditions = () => {
    return (
        <Fragment>
            <Header />
           <section className="Privacypolicy">
            <div className="container">
                <div className="row">
                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                        <div className="Privacypolicyheader">
                            <h1 className="Privacy">Terms and <span className='mycolor'> Conditions </span></h1>
                        </div>
                    </div>
                </div>
            </div>
           </section>
           <section className="Privacypolicy2">
            <div className="container">
                <div className="row">
                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                        <div className="Privacypolicyheader">
                            <h2 className="Privacy">Terms and <span className='mycolor'> Conditions </span></h2>
                            <p>Welcome to our HR Essentia website. We are pleased to offer you access to our services subject to the following terms and conditions. Please read them carefully before using our website.</p>
                            <h6>Acceptance of Terms:</h6>
                            <p>By using our website, you agree to these terms and conditions. You may not use our website if you disagree with the same. We reserve the right to change these terms and conditions at any time without notice, and your continued use of our website following any changes indicates your acceptance of the new terms.</p>
               <h6>Description of Service:</h6>
               <p>Our website provides HR outsourcing services to businesses. These services may include but are not limited to payroll processing, benefits administration, employee record-keeping, compliance reporting, etc.</p>
               <h6>Changes to Terms: </h6>
               <p>The website reserves the right to change the terms at any time, effective upon posting updated terms on the site.</p>
               <h6>Privacy Policy: </h6>
               <p>Use of the website is also subject to the website's privacy policy.</p>
               <h6>User Conduct:</h6>
               <p>The user agrees to use the website only for lawful purposes and in a manner that does not infringe the rights of or restrict or inhibit the use of the website by any third party for other purposes.</p>
               <h6>Intellectual Property:</h6>
               <p>The website's content, including but not limited to text, software, photographs, graphics, videos, and music, is protected by copyright, trademark, and other applicable laws.</p>
               <h6>Disclaimer of Warranties: </h6>
               <p>The website provides the service on an "as is" basis and makes no warranties, representations, or conditions of any kind, express or implied. We make no warranties or representations, express or implied, about the accuracy or completeness of the information provided on our website. We will not be liable for any loss or damage from using our website or any information. </p>
               <h6>Limitation of Liability:</h6>
               <p>The website will not be liable for any damages of any kind arising from the use of the site, including but not limited to indirect or consequential loss or damage, loss of profits, loss of data, or loss of business opportunities.</p>
               <h6>Indemnification:</h6>
               <p>You agree to indemnify and hold us harmless from and against any claims, damages, liabilities, costs, and expenses (including reasonable attorneys’ fees) arising from your use of our website or our services.</p>
               <h6>Termination:</h6>
               <p>We reserve the right to terminate our services at any time without notice. We also reserve the right to terminate your website access if we believe you have violated these terms and conditions.</p>
               <h6>Governing Law:</h6>
               <p>The terms will be governed and interpreted by the laws of the jurisdiction in which the website is located.</p>
                        </div>
                    </div>
                </div>
            </div>
           </section>
         <Footer1 />
        </Fragment>
    );
}
 
export default Termsandconditions;