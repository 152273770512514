import { Fragment } from "react";
import Footer1 from "../component/layout/footer";
import Header from "../component/layout/header";
import thankyou from "../assets/images/thankyou.png";



const Thankyou = () => {
    return (
        <Fragment>
            <Header />
            <section className="thankyou">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="thankyoubox">
         <img src={thankyou} alt="" className="thankyou" />
            <h3>Thank you for filling<span className='mycolor'> out this form</span></h3>
          <p className="updates">
          You don't need to do anything else. We have got your details. Please allow <br />time for processing. We will get back to you shortly. 
          </p>
        
         
        </div>
      </div>
    </div>
  </div>
</section>
              <Footer1 />
            </Fragment>
    );
}
 
export default Thankyou;