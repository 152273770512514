import { Fragment } from "react";
import Footer1 from "../component/layout/footer";
import Header from "../component/layout/header";


const Disclaimer = () => {
    return (
        <Fragment>
            <Header />
           <section className="Privacypolicy">
            <div className="container">
                <div className="row">
                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                        <div className="Privacypolicyheader">
                            <h1 className="Privacy">Disclaimer</h1>
                        </div>
                    </div>
                </div>
            </div>
           </section>
           <section className="Privacypolicy2">
            <div className="container">
                <div className="row">
                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                        <div className="Privacypolicyheader">
                            <h2 className="Privacy">Disclaimer</h2>
                           <p>HR Essentia aims to simplify companies' lives by providing all essential services in one place. HR Essentia delivers a platform to companies to help them accomplish remote hiring, contract creation, payments to contractors, managing employee documents, easy money transfers, etc. </p>
                           <p>The information provided on our website should not be characterized as a legal concept or legal opinion to hire independent contractors, employees, or service providers. HR Essentia is not allowed to provide services within the practice of law. </p>
                           <p>The information on our HR Essentia website is only for marketing and informational purposes and doesn't constitute legal or financial advice. Decisions related to legal strategies or employment should be made with adequate legal, financial or human resources advisors. HR Essentia doesn't make any representations regarding the completeness or accuracy of the information on its website. HR Essentia advises you to consult a legal or financial advisor before using its services. You hereby acknowledge and approve that you have been allowed to consult with legal counsel. </p>
                           <p>HR Essentia hereby disclaims any liability arising from your usage of any information on this website. HR Essentia is not responsible for any damages, losses or claims arising from your use of this website. All information provided on this website, including but not limited to the tools provided therein or in connection with addition to that, shall be available As-Is and with no warranties whatsoever. You shall also hold HR Essentia harmless from any claims from such materials. </p>
                        </div>
                    </div>
                </div>
            </div>
           </section>
         <Footer1 />
        </Fragment>
    );
}
 
export default Disclaimer;