import { useState } from "react";
import React from "react";
import phonecall from "../../assets/images/phonecall.png";
import { Link, NavLink } from "react-router-dom";
import hrlogo from "../../assets/images/hrlogo.png";

const phoneNumber = "+800-123-4567 6587";
const address = "Beverley, New York 224 USA";


let socialList = [
    {
        iconName: 'icofont-facebook-messenger',
        siteLink: '#',
    },
    {
        iconName: 'icofont-twitter',
        siteLink: '#',
    },
    {
        iconName: 'icofont-vimeo',
        siteLink: '#',
    },
    {
        iconName: 'icofont-skype',
        siteLink: '#',
    },
    {
        iconName: 'icofont-rss-feed',
        siteLink: '#',
    },
]

const Header = () => {
    const [menuToggle, setMenuToggle] = useState(false);
	const [socialToggle, setSocialToggle] = useState(false);
	const [headerFiexd, setHeaderFiexd] = useState(false);

	window.addEventListener("scroll", () => {
		if (window.scrollY > 200) {
			setHeaderFiexd(true);
		} else {
			setHeaderFiexd(false);
		}
	});

    return (
        <header className={`header-section ${headerFiexd ? "header-fixed fadeInUp" : ""}`}>
                   <div className="header-bottom">
                <div className="container">
                    <div className="header-wrapper">
                        <div className="logo">
                            <Link to="/">
                            <img src={hrlogo} className="hrlogo" alt="" />
                           </Link>
                        </div>
                        <div className="menu-area">
                            <div className="menu">
                                <ul className={`lab-ul ${menuToggle ? "active" : ""}`}>
                                <li><NavLink to="/">Home</NavLink></li>
                                   <li><NavLink to="/aboutus">About Us</NavLink></li>
                                    <li><NavLink to="/services">Services</NavLink></li>
                                     <li><NavLink to="/contactus">Contact Us</NavLink></li>
                                     <li>   <div className="headercnt">
								<div className='chatmsg'>
								<img src={phonecall}  alt='' className='chatmessage' />
							 </div>
							  <div className="box-body">
 							 <h3 className="nav-info-box-title">Toll Free Number </h3>
 							 <p><a href="tel:+91 9041888938">+91 90418 88938</a></p>
                            	</div>
								</div></li>                               
                                    
                                </ul>
                            </div>
                            
                           {/* <Link to="/contactus" className="signup"><i className="icofont-users"></i> <span>Join Now</span> </Link> */}

                            <div className={`header-bar d-lg-none ${menuToggle ? "active" : "" }`} onClick={() => setMenuToggle(!menuToggle)}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            {/* <div className="ellepsis-bar d-lg-none" onClick={() => setSocialToggle(!socialToggle)}>
                                <i className="icofont-info-square"></i>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}
 
export default Header;