import React from 'react';



const Updatefaq = () => {
 
  return (
<>
<section className='Updatefaq'>
    <div className='container'>
     
        <div className="col-xxl-12 col-xl-12 col-lg-12 col-12 col-md-12" id='accUpdatefaq'>
  <div className="accordion" id="myAccordion">
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingOne">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseOne"
        >
         What is HR outsourcing?
        </button>
      </h2>
      <div
        id="collapseOne"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          HR outsourcing is the practice of hiring a third-party company to handle various HR functions such as payroll, benefits administration, employee onboarding, compliance, and employee relations. This allows businesses to focus on their core operations while outsourcing time-consuming and complex HR functions to a specialized provider.
          </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingTwo">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseTwo"
        >
          What services does your company offer?
        </button>
      </h2>
      <div
        id="collapseTwo"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          We offer a wide range of services to companies including payroll processing, HR compliance, employee onboarding, performance management, employee relations, etc. 
To know in detail you can visit <span><a className='accserve' href='/services'>our services</a></span> page.
          </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingThree">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseThree"
        >
        What are the benefits of outsourcing HR functions?
        </button>
      </h2>
      <div
        id="collapseThree"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          The benefits of outsourcing HR functions include cost savings, increased efficiency, reduced administrative burden, improved compliance, access to specialized expertise, and enhanced employee experience.
          </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingFour">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseFour"
        >
         How do you ensure data security and confidentiality?
        </button>
      </h2>
      <div
        id="collapseFour"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          We have robust data security and confidentiality measures in place, such as secure servers, encrypted data storage, restricted access, and confidentiality agreements. We also have independent audits and certifications to ensure compliance with industry standards.
          </p>
        </div>
      </div>
    </div>
      <div className="accordion-item">
      <h2 className="accordion-header" id="headingFive">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseFive"
        >
       How do you customize the services to fit a company's unique needs?
        </button>
      </h2>
      <div
        id="collapseFive"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          We work closely with our clients to understand their specific needs and develop tailored solutions. This may involve conducting a needs assessment, collaborating with the company, and providing ongoing support and communication to ensure that services are aligned with the company's goals and culture.
          </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingSix">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseSix"
        >
      How does your company handle employee complaints or concerns?
        </button>
      </h2>
      <div
        id="collapseSix"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          We have processes in place to handle employee complaints or concerns, including a dedicated HR team or help desk that employees can contact. We may also provide training and support to clients on how to handle employee complaints or concerns effectively.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
            </div>


</section>

  </>  )
}

export default Updatefaq