
import React from "react";
import FeatureRegister from "../sidebar/feature-register";
import contctlady from "../../assets/images/contctlady.png";
import Recaptcha from "./captcha";

const contactList = [
    {
        imgUrl: 'assets/images/icon/01.png',
        imgAlt: 'contact icon',
        title: 'Office Address',
        desc: '1201 park street, Fifth Avenue',
     
    },
    {
        imgUrl: 'assets/images/icon/02.png',
        imgAlt: 'contact icon',
        title: 'Phone number',
        desc: '+22698 745 632,02 982 745',
    },
    {
        imgUrl: 'assets/images/icon/03.png',
        imgAlt: 'contact icon',
        title: 'Send email',
        desc: 'adminedukon@gmil.com',
    },
    {
        imgUrl: 'assets/images/icon/04.png',
        imgAlt: 'contact icon',
        title: 'Our website',
        desc: 'www.edukon.com',
    },
]

const Contactsections = () => {
    return (
        <>
     
        <section className="contactbner">
        <div className="container">
            <div className="row">
                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                    <h1>Reach Out<span className='mycolor'> to Us</span></h1>
                    <p className="Are">Are you looking for outsourcing solutions and release all the burden your business is bearing daily?<br />
Contact us and let’s have a free session.</p>
                </div>
            </div>
        </div>
        </section>
        <section className="newcontct">
        <div className="container">
            <div className="row">
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12">
                    <div className="cntctdetail">
                    <i class="icofont-map-pins"></i>
                    <h6 class="title">Office Address</h6>
                     <p>KMG Towers, Sector 67, Sahibzada Ajit Singh <br />Nagar, Punjab</p>
                    </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12">
                    <div className="cntctdetail">
                    <i class="icofont-envelope"></i>
                    <h6 class="title">Email Us</h6>
                     <p><a href="mailto:reach@hressentia.com">reach@hressentia.com</a></p>
                    </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12">
                    <div className="cntctdetail">
                    <i class="icofont-phone"></i>
                    <h6 class="title">Call Us</h6>
                    <p><a href='tel:+919041888938'>+91 90418 88938</a></p>
                    </div>
                </div>
            </div>
        </div>

        </section>
<section className="newcontctbottm">
    <div className="container">
        <div className="row" id="contctbottom">
       <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-6 col-12">
       <div className="fombox">
       <FeatureRegister /> 
       </div>
       </div>
      <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-12">
     <img src={contctlady} alt="" className="contctlady" />
            </div>
  </div>
    </div>
</section>

        </>
    );
}
 
export default Contactsections;