
import React from "react";
import CountUp from 'react-countup';
import Certificate from "./certificate";
const Aboutcomponent = () => {
    return (
        <>
        
        <section className="hrounter" id="Stats">
        <div class="container">
  <div class="row">
   <div className="col-xxl-5 col-xl-5 col-md-6 col-lg-5 col-12" id="blackcounter">
   <h4>HR Essentia Site Status</h4>
  <div className="row" id="blackcounter1">
  <div className="col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12">
      <div class="counter">
      <i class="icofont-search-job"></i>
        {/* <h5 class="timer count-title count-number" data-to="10250" data-speed="1500"></h5> */}
       <h5 className="mycounter">
        <CountUp
  start={50}
  end={10250}
  duration={11.75}
  separator=" "
  decimals={0}
  decimal=","
  // prefix="EUR "
  // suffix=" left"
  onEnd={() => console.log('Ended! 👏')}
  onStart={() => console.log('Started! 💨')}
></CountUp>
</h5>
 <p class="count-text ">Jobs Added</p>
      </div>
    </div>
    <div className="col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12">
      <div class="counter">
      <i class="icofont-building-alt"></i>
      <h5 className="mycounter">
        <CountUp
  start={50}
  end={500}
  duration={11.75}
  separator=" "
  decimals={0}
  decimal=","
  // prefix="EUR "
  // suffix=" left"
  onEnd={() => console.log('Ended! 👏')}
  onStart={() => console.log('Started! 💨')}
></CountUp>
</h5>
        {/* <h5 class="timer count-title count-number" data-to="500" data-speed="1500"></h5> */}
        <p class="count-text ">Companies</p>
      </div>
    </div>
      </div>
      <div className="row" id="blackcounter1">
  <div className="col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12">
      <div class="counter">
      <i class="icofont-file-text"></i>
      <h5 className="mycounter">
        <CountUp
  start={50}
  end={11900}
  duration={11.75}
  separator=" "
  decimals={0}
  decimal=","
  // prefix="EUR "
  // suffix=" left"
  onEnd={() => console.log('Ended! 👏')}
  onStart={() => console.log('Started! 💨')}
></CountUp>
</h5>
        {/* <h5 class="timer count-title count-number" data-to="11900" data-speed="1500"></h5> */}
        <p class="count-text ">Resume</p>
      </div>
    </div>
    <div className="col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12">
      <div class="counter">
      <i class="icofont-group"></i>
      <h5 className="mycounter">
        <CountUp
  start={50}
  end={157}
  duration={11.75}
  separator=" "
  decimals={0}
  decimal=","
  // prefix="EUR "
  // suffix=" left"
  onEnd={() => console.log('Ended! 👏')}
  onStart={() => console.log('Started! 💨')}
></CountUp>
</h5>
        {/* <h5 class="timer count-title count-number" data-to="157" data-speed="1500"></h5> */}
        <p class="count-text ">Members</p>
      </div>
    </div>
  </div>
  </div>
 
  <div className="col-xxl-7 col-xl-7 col-md-6 col-lg-7 col-12">
  <div className="jobs">
<h2>Numbers <span className='mycolor'>say it all</span> </h2>
<p>We have provided HR outsourcing services to several firms, including startups, mid-sized companies, and large corporations. We offer holistic HR solutions to every problem at a budget-friendly investment.</p>
<p>Our success in these engagements is a testament to our ability to provide tailored HR solutions to meet each client's unique needs. We aim to enable the growth of your firm beyond borders and boundaries. </p>
<p>We at HR Essentia strive to provide you with the best services. We manage everything from your employee's information, time and attendance, leave, payroll, etc. </p>
</div>
  </div>
          
  </div>
</div>
</section>


<section className="abtsec32" id="Client">
<div className="container text-center">
    <div className="row">
        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
            <h2>Certifications & <span className='mycolor'> Recognitions</span></h2>
<Certificate />
        </div>
    </div>
    <div id="Certificates"></div>
</div>
    </section>
   
        </>
    );
}
 
export default Aboutcomponent;