import { Component, Fragment } from "react";
import Footer1 from "../component/layout/footer";
import Header from "../component/layout/header";
import Banner from "../component/section/banner";
import Mybanner from "../component/section/mybanner";
import Myhomesec from "../component/section/myhomesec";
import { Link} from "react-router-dom";

import {Helmet} from "react-helmet";


const Home = () => {
    return (
        <Fragment>
            <Header />
<Helmet>
<title>Best Outsourcing Human Resources Firm in Mohali, Chandigarh</title>
<meta name="description" content="HR Essentia can help you with your day-to-day HR struggles without even hiring a real HR. Save your funds and outsource with the best company in Mohali." />
<link rel="canonical" href="https://hressentia.com/" />
</Helmet>
            <Banner />
            <Mybanner />
            <Myhomesec />
            <Footer1 />
        </Fragment>
    );
}
 
export default Home;