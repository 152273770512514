import { Fragment } from "react";
import Footer1 from "../component/layout/footer";
import Header from "../component/layout/header";
import Hiringtabs from "../component/section/hiringtabs";
import Servicepagebanner from "../component/section/servicepagebanner";

import {Helmet} from "react-helmet";


const Services = () => {
    return (
        <Fragment>
            <Header />
<Helmet>
<title>Part-Time HR Management and Consulting in Mohali, Chandigarh</title>
<meta name="description" content="No need to hire a physical HR when HR Essentia can do all the jobs remotely. We can hire for you and even manage your payrolls at the minimum cost." />
<link rel="canonical" href="https://hressentia.com/services" />
</Helmet>
          <Servicepagebanner />
          <Hiringtabs />
          <Footer1 />
        </Fragment>
    );
}
 
export default Services;