import React from 'react';
import consultation from "../../assets/images/consultation.png";
import presentation from "../../assets/images/presentation.png";
import handshake from "../../assets/images/handshake.png";
// import Industries from './industries';
import gentleman from "../../assets/images/gentleman.png";
import ClientsThree from './clients-3';
import FeatureRegister from '../sidebar/feature-register';

import HR from "../../assets/images/HR.webp";
import blackgirl from "../../assets/images/blackgirl.webp";
import Industriesserve from './industriesserve';
import vacancy from "../../assets/images/vacancy.png";
import wages from "../../assets/images/wages.png";
import checklist from "../../assets/images/checklist.png";
import reputation from "../../assets/images/reputation.png";
import consultation2 from "../../assets/images/consultation2.png";
import agreement from "../../assets/images/agreement.png";
import search from "../../assets/images/search.png";
import onboarding33 from "../../assets/images/onboarding33.png";
import requirements from "../../assets/images/requirements.png";
import ClientsFour from './clients-4';
import Updatefaq from './updatefaq';
import jobseeker from "../../assets/images/jobseeker.png";
import companyhiring from "../../assets/images/companyhiring.png";
import Jobseekermodal from './jobseekermodal';
import Companyhiring from './companyhiring';



const Myhomesec = () => {
 
  return (
  <>
  <section className='Myhomesec1'>
  <div className='container'>
    <div className='row'>
  <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
    <h2
     className="wow fadeInUp"
              data-wow-delay="0.6s"
              data-wow-duration="1s"
              style={{
                visibility: "visible",
                animationDuration: "1s",
                animationDelay: "0.6s",
                animationName: "fadeInUp"
              }}
    >We take it from <span className='mycolor'>Chaos to Control</span> </h2>
    <p id="booterheader"
     className="wow fadeInUp"
              data-wow-delay="0.9s"
              data-wow-duration="1s"
              style={{
                visibility: "visible",
                animationDuration: "1s",
                animationDelay: "0.9s",
                animationName: "fadeInUp"
              }}
    >Here is how you can sign in with us and our HR managers will take over the job for you. 
</p>
</div>
    </div>
   </div>
  </section>
  <section className='featuressec1'>
    <div className='container'>
      <div className='row'>
        <div className='col-xxl-3 col-xl-3 col-md-6 col-lg-6 col-12'>
    
               <div class="serviceBox">
        <span className='featspan'>01</span>
            <h3 class="title">Tell us <br/>who you are</h3>
            <div class="service-icon">
            <img src={consultation} alt="" className='consultation' />
                </div>
                <p class="description">
                Fill out the form given along with this section with the required details like your company size and your industry our team will contact you after then. 

                </p>
            </div>
      
        </div>
        <div className='col-xxl-3 col-xl-3 col-md-6 col-lg-6 col-12'>
        <div class="serviceBox">
        <span className='featspan'>02</span>
            <h3 class="title">Present <br/>your needs</h3>
            <div class="service-icon">
         <img src={requirements} alt="" className='consultation' />
                </div>
                <p class="description">
                One of our crew members will reach out to you and ask about your requirements. Whether you want compliance, staffing, or other services.
                </p>
            </div>
        </div>
        <div className='col-xxl-3 col-xl-3 col-md-6 col-lg-6 col-12'>
        <div class="serviceBox">
        <span className='featspan'>03</span>
            <h3 class="title"> Choose <br />your plan</h3>
            <div class="service-icon">
              <img src={presentation} alt="" className='consultation' />
                </div>
                <p class="description">
                We have different plans based on traits like company size, subscription time, add-on services, etc. Choose the one that suits you. 
                </p>
            </div>
        </div>
        <div className='col-xxl-3 col-xl-3 col-md-6 col-lg-6 col-12'>
        <div class="serviceBox">
        <span className='featspan'>04</span>
            <h3 class="title">Begin with <br />HR essentia</h3>
            <div class="service-icon">
            <img src={  handshake} alt="" className='consultation' />
                        </div>
                <p class="description">
                Now that we know what you want, we will begin the process. Focus on the other essentials because we are here for the rest! 
                </p>
            </div>
        </div>
      </div>
    </div>
  </section>

  <section className='industries'>
    <div className='container'>
      <div className='row'>
      <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
        <h2><span className='mycolor'>Industries</span> we work for</h2>
        <p id="booterheader">We serve a wide range of industries. Just tell us your requirements, whether it is finding the right candidate <br/>for the vacancy or managing existing employees. We can do it all.</p>
      </div>
      </div>
    </div>
  </section>
  <ClientsFour />
  {/* <Industries /> */}

  <section className='services'>
<div className='container'>
  <div className='row'>
    <div className='col-xxl-4 col-xl-4 col-md-12 col-lg-12 col-12' id='handling'>
    <div
  className="wow fadeInLeft"
              data-wow-delay="0.9s"
              data-wow-duration="1s"
              style={{
                visibility: "visible",
                animationDuration: "1s",
                animationDelay: "0.9s",
                animationName: "fadeInLeft"
  }}

>
      <div className='allserivces'>
      <span class="subtitle">Services</span>
        <h2>A dedicated <span className='mycolor'>HR agency </span>that does it all</h2>
        <p id="booterheader">We ensure that your company grows throughout the time you are working with HR essentia and even after that.</p>
        <p id="booterheader" className='agency'>Our agency provides all kinds of HR services you need for handling your workers’ data and maintenance. We hire for you, keep your payrolls scheduled, and what not.</p>
          <div className='mybtn'>
          {/* <a className='Quote' href='/services'>View All</a> */}
        </div>
        <img src={gentleman}  alt="" className='gentleman' />
      </div>
      </div>
    </div>
    <div className='col-xxl-8 col-xl-8 col-md-12 col-lg-12 col-12' id='ser-handling'>
      <div className='row' id='fsty'>
       <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-12'>
      <div class="box">
    <div class="boxContent">
    <img src={vacancy} alt="" className='hire' />
      <h4 class="title">Hiring</h4>
      <p class="desc">At HREssentia, we aim to match top...
</p>
      <div className='hringbtn'>
      {/* <a href='/services'>Know More</a> */}
      </div>
    </div>
    </div>
     </div>
     <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-12'>
      <div class="box">
    <div class="boxContent">
    <img src={wages} alt="" className='hire' />
      <h4 class="title">Explicit Services</h4>
      <p class="desc"> HR Essentia also offers explicit...
</p>
      <div className='hringbtn'>
      {/* <a href='/services'>Know More</a> */}
      </div>
    </div>
    </div>
     </div>
  
     <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-12'>
      <div class="box">
    <div class="boxContent">
    <img src={checklist} alt="" className='hire' />
      <h4 class="title">HR Operations</h4>
      <p class="desc"> Let HR Essentia take care of all... </p>
      <div className='hringbtn'>
      {/* <a href='/services'>Know More</a> */}
      </div>
    </div>
    </div>
     </div>
     <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-12'>
      <div class="box">
    <div class="boxContent">
    <img src={onboarding33} alt="" className='hire' />
      <h4 class="title">Posh Compliance</h4>
      <p class="desc">When hiring HR Essentia, you...
</p>
      <div className='hringbtn'>
      {/* <a href='/services'>Know More</a> */}
      </div>
    </div>
    </div>
      </div>
    
     </div>
     <div className='row' id='candidate'>
       <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-12'>
      <div class="box">
    <div class="boxContent">
    <img src={reputation} alt="" className='hire' />
      <h4 class="title">Employee Engagement</h4>
      <p class="desc">At HREssentia, we're all about... </p>
      <div className='hringbtn'>
      {/* <a href='/services'>Know More</a> */}
      </div>
    </div>
    </div>
     </div>
     <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-12'>
      <div class="box">
    <div class="boxContent">
    <img src={consultation2} alt="" className='hire' />
      <h4 class="title">Payroll Outsourcing</h4>
      <p class="desc"> Say goodbye to payroll...</p>
      <div className='hringbtn'>
      {/* <a href='/services'>Know More</a> */}
      </div>
    </div>
    </div>
     </div>
     <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-12'>
      <div class="box">
    <div class="boxContent">
    <img src={checklist} alt="" className='hire' />
      <h4 class="title">Employee Onboarding</h4>
      <p class="desc"> This service comprises basic... </p>
      <div className='hringbtn'>
      {/* <a href='/services'>Know More</a> */}
      </div>
    </div>
    </div>
     </div>
     <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-12'>
      <div class="box">
    <div class="boxContent">
    <img src={agreement} alt="" className='hire' />
      <h4 class="title">Employee Leave & Attendance</h4>
      <p class="desc">HR Essentia is also an expert...</p>
      <div className='hringbtn'>
      {/* <a href='/services'>Know More</a> */}
      </div>
    </div>
    </div>
     </div>
  
     </div>
     <div className='row' id='lasthiring'>
      <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
      <div class="box">
    <div class="boxContent">
    <img src={search} alt="" className='hire2' />
      <h4 class="title">Counseling of Employees</h4>
      <p class="desc">At HR Essentia, we ensure to be with you whenever your employee has any conflict...</p>
      <div className='hringbtn'>
      {/* <a href='/services'>Know More</a> */}
      </div>
    </div>
    </div>
      </div>
      <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
      <div class="box">
    <div class="boxContent"> 
    <img src={checklist} alt="" className='hire2' />
      <h4 class="title">Employee Relations</h4>
      <p class="desc"> We at HR Essentia ensure to set up a compact network ... </p>
      <div className='hringbtn'>
      {/* <a href='/services'>Know More</a> */}
      </div>
    </div>
    </div>
     </div>
     </div>
    </div>
  </div>
</div>
  </section>
  <Industriesserve />
 
  {/* <Sponsor /> */}
  <section className='process'>
<div className='container'>
  <div className='row'>
    <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
      <h2>Cut off all the <span className='mycolor'>extra expenses & make </span> the process smoother</h2>
      <p>HR essentia is a team of experienced professionals working in the HR department for decades. We present the most suitable plan based on the size of your firm.</p>
      <p>Every organization has a different style of working, we find it out and suggest you the most promising solutions.</p>
      <p> With years of experience, we have developed a sense of authority, and the recruiters we provide will make the perfect match for your vacancy & candidate.</p>
      <div className='mybtn'>
          <a className='Quote' href='/contactus'>Contact Us</a>
        </div>
    </div>
    <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
    
    <img src={HR}  alt="" className='newhr' />
    </div>
  </div>
</div>
  </section>
  
  <section className='jobseekr'>
<div className='container'>
<div className='row'>
  <div className='col-xxl-12 col-xl-12 col-md-12 col-12 col-lg-12'>
    <h2>We’d Love To Talk About <span className='mycolor'>Your Goals</span></h2>
    <p id="booterheader" class="organiation">Looking for your dream job or top-notch talent for your organization? Look no further than HR Essentia! <br />Our expert recruiters will provide personalized assistance in finding the perfect match for your needs.<br /> Say goodbye to your hiring woes and hello to success with HR Essentia.</p>
  </div>
</div>
  <div className='row' id='jobhiring'>
    <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
    <div className="jbeeker">
    <img src={jobseeker} alt="" className='jobseeker' />
    <div className="jobseeker-content">
    <h4>Apply for a job now! </h4>
    <p>Are you currently looking for employment? Apply for a job opening with us; please click the button below.</p>
 <Jobseekermodal />
  </div>

</div>
    </div>
   
    <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
    <div className="companyhiringcontent">
    <img src={companyhiring} alt="" className='companyhiring' />

  <div className="companyhiring-content">
  <h4>Find out the best talent! </h4>
    <p>Finding the finest talent for your company? Submit your company details to us; please click the button below. </p>
<Companyhiring />
  </div>
</div>
  
    </div>
  </div>
</div>
  </section>
  
  {/* <section className='abtfreature'>
    <div className='container'>
      <div className='row'>
        <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
          <h2>Lesser Expense <span className='mycolor'>Smoother Process</span></h2>
       <p className='organization'>Every organization has a different style of working, we find it out and suggest you the most promising solutions.<br /> With years of experience, we have developed a sense of authority, and the recruiters we provide <br />will make the perfect match for your vacancy & candidate</p>
      <img src={aboutfreature} className="aboutfreature" alt='' />
        </div>
      </div>
    </div>
  </section> */}

  <ClientsThree />
  
{/* <section className='contactsec'>
<div className='container'>
  <div className='row' id='Expense'>
  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-4 col-12' id='bbg'>
  <img src={blackgirl} className="blackgirl" alt='' />
  </div>
  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
  <div className='homeform'>
  <FeatureRegister />
  </div>
  </div>
  </div>
</div>
</section> */}
<section className='faqsec'>
  <div className='container'>
    <div className='row'>
    <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
      <h2>Frequently Asked <span className='mycolor'>Questions?</span></h2>
      <p id="booterheader"className='organiation'>Every organization has a different style of working, we find it out and suggest you the most promising solutions.<br /> With years of experience, we have developed a sense of authority, and the recruiters we provide <br />will make the perfect match for your vacancy & candidate</p>
 
      <Updatefaq />
      </div>
    </div>
   
  </div>
</section>

  </>  )
}

export default Myhomesec