import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import React from 'react';
import 'react-tabs/style/react-tabs.css';
import onboardingserv from "../../assets/images/onboardingserv.webp" ;
import paperwork from "../../assets/images/paperwork.webp" ;
import Conflict from "../../assets/images/Conflict.webp";
import EmployeeEngagement from "../../assets/images/EmployeeEngagement.webp";
import Performance from "../../assets/images/Performance.webp";
import Datasecurity from "../../assets/images/Datasecurity.webp";
import Expertsupport from "../../assets/images/Expertsupport.webp";
import Scalability  from "../../assets/images/Scalability.webp";
import Compensation  from "../../assets/images/Compensation.webp";
import audit  from "../../assets/images/audit.webp";
import deveolp  from "../../assets/images/deveolp.webp";
import complaint  from "../../assets/images/complaint.webp";


const Hiringtabs = () => {
    return (
   <>
      <section className="bottom sersec" id='Hiring'>
        <div className="container">
            <div className="row">
                <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                    <p className="working">We provide a wide range of services. Just tell us your requirements, whether it is finding the right candidate <br/> for the vacancy or managing existing employees. We can do it all.</p>
                </div>
            </div>
        </div>
        </section>
        <section className="alsersections" >
            <div className="container">
            <div className="row">
        <div className="col-xxl-4 col-xl-4 col-md-6 col-lg-4 col-12">
        <div className="cacilds">
        <h6 className="How">How We Work</h6>
        <h2>Key points</h2>
   <ul className="timeline">
  <li className="timeline-inverted">
    <div className="timeline-badge" id="firstbadge">01</div>
    <div className="timeline-panel">
      <div className="flex-container flex-around">
        <div className="timeline-heading">
          <h5 className="timeline-title">Job Analysis</h5>
         <p>Before beginning the hiring process, it's important to have a clear understanding of the job requirements.</p>
        </div>
              </div>
    </div>
  </li>
  <li className="timeline-inverted">
    <div className="timeline-badge" id="secondbadge">02</div>
    <div className="timeline-panel">
      <div className="flex-container flex-around">
        <div className="timeline-heading">
        <h5 className="timeline-title">Sourcing</h5>
        <p>Once the job analysis is complete, we identify potential candidates.</p>
        </div>
         </div>
    </div>
  </li>
  <li className="timeline-inverted">
    <div className="timeline-badge" id="firstbadge">03</div>
    <div className="timeline-panel">
      <div className="flex-container flex-around">
        <div className="timeline-heading">
        <h5 className="timeline-title">Interviewing</h5>
          <p>Once candidates pass the screening stage, we conduct interviews to further assess their qualifications & experience.
</p>
        </div>
     </div>
    </div>
  </li>

</ul>
</div>
        </div>
        <div className="col-xxl-8 col-xl-8 col-md-6 col-lg-8 col-12" id="perfect">
        <div className="hiringcontent">
            <h2><span className='mycolor'>Hiring</span></h2>
           <p>At HR Essentia, we aim to match top-notch talent with the perfect niche! We're all about keeping it in the family, so we find candidates with the right skill set and background.</p>
         <p>Hiring is a critical function of HR operations as it involves the process of identifying, attracting, and selecting the best candidate for a particular job position.</p>
         <p>Once a candidate is selected, HR operations will extend a job offer and work with the candidate to complete the onboarding process, which includes new hire paperwork, orientation, and training.</p>
         <p>After the new hire is onboarded, we monitor their performance and ensure that they are meeting the expectations of the job. If performance issues arise, we may take corrective action to address the problem. </p>
<ul class="lab-ul">
        <li><i class="icofont-tick-mark"></i>Effective</li>
        <li><i class="icofont-tick-mark"></i>Efficient</li>
        <li><i class="icofont-tick-mark"></i>Eligible</li>
             </ul>
        </div>
        </div>
        </div>
      </div>
      <div  id='Onboarding'></div>
        </section>
     
<section className='hiringserivce desk'>
   <div className='container'>
    <Tabs>
    <div className="row row--30">
      <div className="order-2 order-lg-1 col-xxl-6 col-xl-l col-lg-6 col-md-12 col-sm-12 col-12 mt_md--30 mt_sm--30" id='headintb'>
    <h2>Employee <span className='mycolor'> Onboarding</span></h2>
    <p>This service comprises basic formalities in terms of Offer letters and mailing the Offer to the eligible selected candidates for a smooth onboarding process.</p>
<p>Employee onboarding is integrating new hires into an organization and providing them with the tools, resources, and information they need to succeed in their new role.</p>
<p>Effective onboarding can have a significant impact on an employee's long-term success and job satisfaction. It typically involves orientation, training, and socialization, as well as providing access to company resources and introducing the employee to the team and key stakeholders. By investing in a comprehensive onboarding program, we can help your new hires feel welcome, connected, and prepared to contribute to the company's success.</p>
    <div className="advance-tab-button advance-tab-button-1">
    <TabList>
      <Tab>
      <div className='hrtabtile'>
      <i class="fa fa-users" aria-hidden="true"></i>
      <br />
      Orientation
      </div></Tab>
      <Tab>
      <div className='hrtabtile'>
      <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
      <br />
      Paper Work
            </div></Tab>
      <Tab><div className='hrtabtile'>
      <i class="fa fa-lightbulb-o" aria-hidden="true"></i>
      <br />
      Training
            </div></Tab>
    </TabList>
    </div>
    </div>
    <div className="order-1 order-lg-2 col-xxl-6 col-xl-l col-lg-6  col-md-12 col-sm-12 col-12">
    <TabPanel>
    <img src={onboardingserv} className="tabhrteam" alt='' />
    <div className='hrtabcontent'>
      <h5>Orientation</h5>
      <p>On the new hire's first day, we provide an orientation that introduces them to the company culture, values, and policies.  </p>
</div>
    </TabPanel>
   
    <TabPanel>
    <img src={paperwork} className="tabhrteam" alt='' />
    <div className='hrtabcontent'>
      <h5>Paperwork</h5>
     <p>The HREssentia takes care of the entire documentation part starting from day one wherein onboarding of an employee, joining letters, post-joining documentation is done, and even many more formalities which are solely managed by us to fulfill the criteria.
</p>
</div>
    </TabPanel>
    <TabPanel>
    <img src={onboardingserv} className="tabhrteam" alt='' />
    <div className='hrtabcontent'>
      <h5>Training</h5>
     <p>We provide an induction to the newly joined employees in the organization for a better vision and understanding of the HR rules and guidelines of the companies.</p>
</div>
      
    </TabPanel>
    </div>
    </div>
    </Tabs>
    </div>
</section>
<section className='hiringserivce mobile'>
   <div className='container'>
    <Tabs>
    <div className="row row--30">
      <div className="order-1 order-lg-1 col-xxl-6 col-xl-l col-lg-6 col-md-12 col-sm-12 col-12 mt_md--30 mt_sm--30" id='headintb'>
    <h2>Employee <span className='mycolor'> Onboarding</span></h2>
    <p>This service comprises basic formalities in terms of Offer letters and mailing the Offer to the eligible selected candidates for a smooth onboarding process.</p>
<p>Employee onboarding is integrating new hires into an organization and providing them with the tools, resources, and information they need to succeed in their new role.</p>
<p>Effective onboarding can have a significant impact on an employee's long-term success and job satisfaction. It typically involves orientation, training, and socialization, as well as providing access to company resources and introducing the employee to the team and key stakeholders. By investing in a comprehensive onboarding program, we can help your new hires feel welcome, connected, and prepared to contribute to the company's success.</p>
   
    </div>
    <div className="order-2 order-lg-2 col-xxl-6 col-xl-l col-lg-6  col-md-12 col-sm-12 col-12">
    <div className="advance-tab-button advance-tab-button-1">
    <TabList>
      <Tab>
      <div className='hrtabtile'>
      <i class="fa fa-users" aria-hidden="true"></i>
      <br />
      Orientation
      </div></Tab>
      <Tab>
      <div className='hrtabtile'>
      <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
      <br />
      Paper Work
            </div></Tab>
      <Tab><div className='hrtabtile'>
      <i class="fa fa-lightbulb-o" aria-hidden="true"></i>
      <br />
      Training
            </div></Tab>
    </TabList>
    </div>
    <TabPanel>
    <img src={onboardingserv} className="tabhrteam" alt='' />
    <div className='hrtabcontent'>
      <h5>Orientation</h5>
      <p>On the new hire's first day, we provide an orientation that introduces them to the company culture, values, and policies.  </p>
</div>
    </TabPanel>
   
    <TabPanel>
    <img src={paperwork} className="tabhrteam" alt='' />
    <div className='hrtabcontent'>
      <h5>Paperwork</h5>
     <p>The HREssentia takes care of the entire documentation part starting from day one wherein onboarding of an employee, joining letters, post-joining documentation is done, and even many more formalities which we solely manage to fulfill the criteria.
</p>
</div>
    </TabPanel>
    <TabPanel>
    <img src={onboardingserv} className="tabhrteam" alt='' />
    <div className='hrtabcontent'>
      <h5>Training</h5>
     <p> We provide an induction to the newly joined employees in the organization for a better vision and understanding of the HR rules and guidelines of the companies.</p>
</div>
      
    </TabPanel>
    </div>
    </div>
    </Tabs>
    </div>
</section>
  <div id='Operations'></div>

<section className='serpgsec3'>
<div className='container'>
   <div className='row'>
   
       <div className='col-xxl-5 col-xl-5 col-md-5 col-lg-5 col-12'>
       <div className='top-notch'>
       <h3>Key <span className='mycolor'> points</span></h3>
       <ul class="lab-ul">
        <li><i class="icofont-tick-mark"></i>Recruitment and Staffing</li>
        <li><i class="icofont-tick-mark"></i>Compensation and Benefits</li>
        <li><i class="icofont-tick-mark"></i>Grievance Handling</li>
        <li><i class="icofont-tick-mark"></i>Performance Management</li>
        <li><i class="icofont-tick-mark"></i>Employee Relations</li>
        <li><i class="icofont-tick-mark"></i>Compliance</li>
             <li><i class="icofont-tick-mark"></i>Diversity and Inclusion</li>
        </ul>
    
      </div>
       </div>
       <div className='col-xxl-7 col-xl-7 col-md-7 col-lg-7 col-12'>
<h2>HR <span className='mycolor'>Operations</span></h2>
{/* <h5 className='match'>At HR Essentia, we aim to match top-notch talent with the perfect niche!</h5> */}
<p>Let HR Essentia take care of all your HR operations! We've covered you, from finding top talent with specific skills and niches to seamless onboarding. Plus, we handle all the nitty-gritty details like KYC, documentation, and record-keeping in hard and soft forms. Trust us to keep you compliant with the latest HR guidelines.</p>
<p className='aim'>HR operations involve managing HR processes and procedures that support the organization's employees and overall business goals. </p>

<p>HR operations are critical to ensuring that an organization's employees are supported, engaged, and equipped with the tools they need to be successful in their jobs, while also ensuring compliance with applicable laws and regulations.</p>

<div class="mybtn"><a class="Quote" href="/contactus">Contact Us</a></div>
       </div>
        </div>
</div>

</section>
<div id='ER'></div>
<section className='hiringserivce3 desk'>
   <div className='container'>
    <Tabs>
    <div className="row row--30">
      <div className="order-2 order-lg-1 col-xxl-6 col-xl-l col-lg-6 col-md-12 col-sm-12 col-12 mt_md--30 mt_sm--30" id='headintb'>
    <h2>Employee <span className='mycolor'> Relations</span></h2>
<p>Employee relations refer to the management of relationships between employees and the employer. </p>
<p>HR Essentia provides a range of support to companies in managing their employee relations. This support may include developing and implementing HR policies and procedures, managing employee benefits and compensation, and handling employee grievances and disputes. </p>
<p>Overall, employee relations are critical to maintaining a positive and productive workplace culture. We play a crucial role in managing these relationships and ensuring that both employees and the organization can thrive.</p>

    <div className="advance-tab-button advance-tab-button-1">
    <TabList>
      <Tab>
      <div className='hrtabtile'>
      <i class="fa fa-users" aria-hidden="true"></i>
      <br />
    Conflict <br />Resolution
      </div></Tab>
      <Tab>
      <div className='hrtabtile'>
      <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
      <br />
      Employee<br /> Engagement
            </div></Tab>
      <Tab><div className='hrtabtile'>
      <i class="fa fa-lightbulb-o" aria-hidden="true"></i>
      <br />
      Performance <br /> Management
            </div></Tab>
    </TabList>
    </div>
    </div>
    <div className="order-1 order-lg-2 col-xxl-6 col-xl-l col-lg-6  col-md-12 col-sm-12 col-12">
    <TabPanel>
    <img src={Conflict} className="tabhrteam" alt='' />
    <div className='hrtabcontent'>
      <h5>Conflict Resolution</h5>
      <p>We handle employee conflicts and provide a fair and timely resolution. </p>
</div>
    </TabPanel>
    <TabPanel>
    <img src={EmployeeEngagement} className="tabhrteam" alt='' />
    <div className='hrtabcontent'>
      <h5>Employee Engagement</h5>
      <p>We work to engage employees and promote employee satisfaction. </p>
</div>
      
    </TabPanel>
    <TabPanel>
    <img src={Performance} className="tabhrteam" alt='' />
    <div className='hrtabcontent'>
      <h5>Performance Management</h5>
      <p>We ensure that performance management practices are fair, transparent, and consistent. </p>
</div>
   
    </TabPanel>
    </div>
    </div>
    </Tabs>
    </div>
</section>
<section className='hiringserivce3 mobile'>
   <div className='container'>
    <Tabs>
    <div className="row row--30">
      <div className="order-1 order-lg-1 col-xxl-6 col-xl-l col-lg-6 col-md-12 col-sm-12 col-12 mt_md--30 mt_sm--30" id='headintb'>
    <h2>Employee <span className='mycolor'> Relations</span></h2>
    <p>Employee relations refer to the management of relationships between employees and the employer. </p>
<p>HR Essentia provides a range of support to companies in managing their employee relations. This support may include developing and implementing HR policies and procedures, managing employee benefits and compensation, and handling employee grievances and disputes. </p>
<p>Overall, employee relations are critical to maintaining a positive and productive workplace culture. We play a crucial role in managing these relationships and ensuring that both employees and the organization can thrive.</p>
       </div>
    <div className="order-2 order-lg-2 col-xxl-6 col-xl-l col-lg-6  col-md-12 col-sm-12 col-12">
    <div className="advance-tab-button advance-tab-button-1">
    <TabList>
      <Tab>
      <div className='hrtabtile'>
      <i class="fa fa-users" aria-hidden="true"></i>
      <br />
    Conflict <br />Resolution
      </div></Tab>
      <Tab>
      <div className='hrtabtile'>
      <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
      <br />
      Employee<br /> Engagement
            </div></Tab>
      <Tab><div className='hrtabtile'>
      <i class="fa fa-lightbulb-o" aria-hidden="true"></i>
      <br />
      Performance <br /> Management
            </div></Tab>
    </TabList>
    </div>
    <TabPanel>
    <img src={Conflict} className="tabhrteam" alt='' />
    <div className='hrtabcontent'>
      <h5>Conflict <br /> Resolution</h5>
      <p>We handle employee conflicts and provide a fair and timely resolution. </p>
</div>
    </TabPanel>
    <TabPanel>
    <img src={EmployeeEngagement} className="tabhrteam" alt='' />
    <div className='hrtabcontent'>
      <h5>Employee Engagement</h5>
      <p>We work to engage employees and promote employee satisfaction. </p>
</div>
      
    </TabPanel>
    <TabPanel>
    <img src={Performance} className="tabhrteam" alt='' />
    <div className='hrtabcontent'>
      <h5>Performance Management</h5>
      <p>We ensure that performance management practices are fair, transparent, and consistent.</p>
</div>
   
    </TabPanel>
    </div>
    </div>
    </Tabs>
    </div>
</section>
<div id='Engagement'></div>
 <section className="alsersections4">
            <div className="container">
            <div className="row">
        <div className="col-xxl-4 col-xl-4 col-md-6 col-lg-4 col-12">
        <div className="cacilds">
        <h6 className="How">How We Work</h6>
      <h2>Key points </h2>
   <ul className="timeline">
  <li className="timeline-inverted">
    <div className="timeline-badge" id="firstbadge">01</div>
    <div className="timeline-panel">
      <div className="flex-container flex-around">
        <div className="timeline-heading">
          <h5 className="timeline-title">Communication</h5>
         <p>We maintain effective communication between employees and management.
</p>
        </div>
              </div>
    </div>
  </li>
  <li className="timeline-inverted">
    <div className="timeline-badge" id="secondbadge">02</div>
    <div className="timeline-panel">
      <div className="flex-container flex-around">
        <div className="timeline-heading">
        <h5 className="timeline-title">Recognition and Rewards</h5>
        <p>We recognize and reward employees for their extraordinary achievements and innovative ideas for the upliftment of the organization.</p>
        </div>
         </div>
    </div>
  </li>
  <li className="timeline-inverted">
    <div className="timeline-badge" id="firstbadge">03</div>
    <div className="timeline-panel">
      <div className="flex-container flex-around">
        <div className="timeline-heading">
        <h6 className="timeline-title">Work-Life Balance</h6>
          <p>We encourage work-life balance by offering flexible work arrangements, such as telecommuting or flexible schedules, etc.</p>
        </div>
     </div>
    </div>
  </li>

</ul>
</div>
        </div>
        <div className="col-xxl-8 col-xl-8 col-md-6 col-lg-8 col-12" id="perfect">
        <div className="hiringcontent">
            <h2>Employee <span className='mycolor'> Engagement</span></h2>
          <p>At HREssentia, we're all about boosting employee engagement and consistency in your organization! With our strategic approach, we help you organize refresher courses, employee activities, and town halls to keep your team connected and motivated. Let us help you create a workplace that fosters growth, learning, and collaboration.</p>
         <p>Employee engagement refers to the level of commitment, motivation, and satisfaction that employees feel toward their jobs and their organization.</p>
         <p>Overall, employee engagement is critical to maintaining a motivated, productive, and satisfied workforce. HR operations play a key role in fostering employee engagement through effective communication, recognition and rewards, professional development, and work-life balance.</p>
<ul class="lab-ul">
        <li><i class="icofont-tick-mark"></i>Wellness programs</li>
        <li><i class="icofont-tick-mark"></i>Engagement surveys</li>
        <li><i class="icofont-tick-mark"></i>Leadership development.</li>
      
        </ul>
        </div>
        </div>
        </div>
      </div>
        </section>
  <section className='hiringserivce5 desk' id='Payroll'>
   <div className='container'>
    <Tabs>
    <div className="row row--30">
      <div className="order-2 order-lg-1 col-xxl-6 col-xl-l col-lg-6 col-md-12 col-sm-12 col-12 mt_md--30 mt_sm--30" id='headintb'>
    <h2>Payroll <span className='mycolor'> Outsourcing</span></h2>
    <p>Say goodbye to payroll headaches with HREssentia! Our expert team takes care of the most time-consuming processes, like preparing monthly payrolls based on attendance records from your Company. Trust us to handle your payroll needs with precision and efficiency.</p>
    <p>Outsourcing payroll can help you save time and focus on essential tasks such as employee engagement, recruitment, and training. We can help you save time as we are an expert in payroll processing and can complete tasks accurately and quickly. </p>
    <p>We stay current with ever-changing tax laws and regulations, minimizing the risk of costly mistakes. We have robust security protocols and systems to protect employee data, reducing the risk of data breaches or other security incidents. </p>
    <div className="advance-tab-button advance-tab-button-1">
    <TabList>
      <Tab>
      <div className='hrtabtile'>
      <i class="fa fa-users" aria-hidden="true"></i>
      <br />
      Scalability
      </div></Tab>
      <Tab>
      <div className='hrtabtile'>
      <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
      <br />
      <span className='Expert'>Expert</span> support 
            </div></Tab>
      <Tab><div className='hrtabtile'>
      <i class="fa fa-lightbulb-o" aria-hidden="true"></i>
      <br />
      Data security
            </div></Tab>
    </TabList>
    </div>
    </div>
    <div className="order-1 order-lg-2 col-xxl-6 col-xl-l col-lg-6  col-md-12 col-sm-12 col-12">
    <TabPanel>
    <img src={Scalability} className="tabhrteam" alt=''  />
    <div className='hrtabcontent'>
      <h5> Scalability </h5>
      <p>We offer versatile solutions that adapt to your business's growth, ensuring we keep up with your ever-evolving needs. </p>
</div>
    </TabPanel>
    <TabPanel>
    <img src={Expertsupport} className="tabhrteam" alt='' />
    <div className='hrtabcontent'>
      <h5>Expert Support </h5>
      <p>Let our team of experts take the load off your shoulders! We offer comprehensive support and guidance on all matters.  </p>
</div>
      
    </TabPanel>
    <TabPanel>
    <img src={Datasecurity} className="tabhrteam" alt='' />
    <div className='hrtabcontent'>
      <h5>Data Security</h5>
      <p>We ensure 100% protection of your employee’s data from data breaches and other security incidents. </p>
</div>
    </TabPanel>
    </div>
    </div>
    </Tabs>
    </div>
</section>
  <section className='hiringserivce5 mobile' id='Payroll'>
   <div className='container'>
    <Tabs>
    <div className="row row--30">
      <div className="order-1 order-lg-1 col-xxl-6 col-xl-l col-lg-6 col-md-12 col-sm-12 col-12 mt_md--30 mt_sm--30" id='headintb'>
    <h2>Payroll <span className='mycolor'> Outsourcing</span></h2>
    <p>Say goodbye to payroll headaches with HREssentia! Our expert team takes care of the most time-consuming processes, like preparing monthly payrolls based on attendance records from your Company. Trust us to handle your payroll needs with precision and efficiency.</p>
    <p>Outsourcing payroll can help you save time and focus on essential tasks such as employee engagement, recruitment, and training. We can help you save time as we are an expert in payroll processing and can complete tasks accurately and quickly. </p>
    <p>We stay current with ever-changing tax laws and regulations, minimizing the risk of costly mistakes. We have robust security protocols and systems to protect employee data, reducing the risk of data breaches or other security incidents. </p>
   
    </div>
    <div className="order-2 order-lg-2 col-xxl-6 col-xl-l col-lg-6  col-md-12 col-sm-12 col-12">
    <div className="advance-tab-button advance-tab-button-1">
    <TabList>
      <Tab>
      <div className='hrtabtile'>
      <i class="fa fa-users" aria-hidden="true"></i>
      <br />
      Scalability
      </div></Tab>
      <Tab>
      <div className='hrtabtile'>
      <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
      <br />
      <span className='Expert'>Expert</span> support 
            </div></Tab>
      <Tab><div className='hrtabtile'>
      <i class="fa fa-lightbulb-o" aria-hidden="true"></i>
      <br />
      Data security
            </div></Tab>
    </TabList>
    </div>
    <TabPanel>
    <img src={Scalability} className="tabhrteam" alt=''  />
    <div className='hrtabcontent'>
      <h5> Scalability</h5>
      <p>We offer flexible solutions for companies of all sizes. We meet the changing needs of a business. </p>
</div>
    </TabPanel>
    <TabPanel>
    <img src={Expertsupport} className="tabhrteam" alt='' />
    <div className='hrtabcontent'>
      <h5>Expert support </h5>
      <p>Our experts offer full support and advice on payroll-related issues, reducing the workload.</p>
</div>
      
    </TabPanel>
    <TabPanel>
    <img src={Datasecurity} className="tabhrteam" alt='' />
    <div className='hrtabcontent'>
      <h5>Data security</h5>
      <p>We ensure 100% protection of your employee’s data from data breaches and other security incidents. </p>
</div>
    </TabPanel>
    </div>
    </div>
    </Tabs>
    </div>
</section>


<div id='Attendance'></div>
<section className='serpgsec6'>
<div className='container'>
   <div className='row'>
   
       <div className='col-xxl-5 col-xl-5 col-md-5 col-lg-5 col-12'>
       <div className='top-notch'>
       <h3>Key Features:</h3>
       <ul class="lab-ul">
        <li><i class="icofont-tick-mark"></i>Attendance tracking</li>
        <li><i class="icofont-tick-mark"></i>Creating and enforcing leave policies </li>
        <li><i class="icofont-tick-mark"></i>Tracking employee leave accrual </li>
        <li><i class="icofont-tick-mark"></i>Managing leave requests</li>
        <li><i class="icofont-tick-mark"></i>Generating attendance report  </li>
        <li><i class="icofont-tick-mark"></i>Managing leave balances  </li>
        <li><i class="icofont-tick-mark"></i>Compliance with laws and regulations </li>
  
        </ul>
      </div>
       </div>
       <div className='col-xxl-7 col-xl-7 col-md-7 col-lg-7 col-12'>
<h2>Employee <span className='mycolor'>Leave & Attendance</span></h2>
<p>Say goodbye to the hassle of employee leave and attendance management! HR Essentia's expert team has got you covered with our cutting-edge time-tracking tools, comprehensive database management, and cost-effective solutions. </p>
<p className='aim'>Let us help you save valuable time and avoid costly mistakes, while you focus on what matters - your business.</p>
<p>At HR Essentia, we're experts in formulating data related to leaves, attendance policies, and database management. We keep track of all available leave records so you don't have to. </p>
<p>HR operations involve generating attendance reports, which provide information on employee attendance, tardiness, and absenteeism. These reports can help managers identify attendance patterns and take corrective action if necessary.</p>
<div class="mybtn"><a class="Quote" href="/contactus">Contact Us</a></div>
       </div>
        </div>
</div>

</section>
<section className='hiringserivce7 desk'>
   <div className='container'>
    <Tabs>
    <div className="row row--30">
      <div className="order-2 order-lg-1 col-xxl-6 col-xl-l col-lg-6 col-md-12 col-sm-12 col-12 mt_md--30 mt_sm--30" id='headintb'>
    <h2>Explicit <span className='mycolor'> Services</span></h2>
   <p>HR Essentia also offers explicit services; we take care of what no other service providers do, such as special bonds and agreements to ensure employees are consistent in their job and have a minimal deterioration rate to boost your Company's overall productivity and growth.</p>
  <p>Our HR services are clearly defined, aligned, and measurable with the organizational goals. Our explicit services include job postings, resume screening, reference checks, candidate interviews, and job offers.</p>
  <p>HR Essentia also manages the performance of your employees by setting performance goals, providing feedback and coaching, and conducting performance evaluations. These services are designed to enhance employee performance with specific outcomes such as improved productivity and employee engagement. </p>

    <div className="advance-tab-button advance-tab-button-1">
    <TabList>
      <Tab>
      <div className='hrtabtile'>
      <i class="fa fa-users" aria-hidden="true"></i>
      <br />
      Compensation & Benefits 
      </div></Tab>
      <Tab>
      <div className='hrtabtile'>
      <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
      <br />
      Training & Development
            </div></Tab>
      <Tab><div className='hrtabtile'>
      <i class="fa fa-lightbulb-o" aria-hidden="true"></i>
      <br />
      Recruitment and Hiring
            </div></Tab>
    </TabList>
    </div>
    </div>
    <div className="order-1 order-lg-2 col-xxl-6 col-xl-l col-lg-6  col-md-12 col-sm-12 col-12">
    <TabPanel>
    <img src={Compensation} className="tabhrteam" alt='' />
    <div className='hrtabcontent'>
      <h5>Compensation and benefits </h5>
      <p>We ensure that the employees are fairly compensated and provided with appropriate benefits. </p>
</div>
    </TabPanel>
    <TabPanel>
    <img src={deveolp} className="tabhrteam" alt='' />
    <div className='hrtabcontent'>
      <h5>Training and development </h5>
      <p>We help employees improve their skills and knowledge with increased productivity and job satisfaction.</p>
</div>
      
    </TabPanel>
    <TabPanel>
    <img src={onboardingserv} className="tabhrteam" alt='' />
    <div className='hrtabcontent'>
      <h5>Recruitment and hiring  </h5>
      <p>We help you fill open positions with qualified candidates. </p>
</div>
    </TabPanel>
    </div>
    </div>
    </Tabs>
    </div>
</section>
<section className='hiringserivce7 mobile'>
   <div className='container'>
    <Tabs>
    <div className="row row--30">
      <div className="order-1 order-lg-1 col-xxl-6 col-xl-l col-lg-6 col-md-12 col-sm-12 col-12 mt_md--30 mt_sm--30" id='headintb'>
    <h2>Explicit <span className='mycolor'> Services</span></h2>
   <p>HR Essentia also offers explicit services; we take care of what no other service providers do, such as special bonds and agreements to ensure employees are consistent in their job and have a minimal deterioration rate to boost your Company's overall productivity and growth.</p>
   <p>Our HR services are clearly defined, aligned, and measurable with the organizational goals. Our explicit services are comprised of job postings, resume screening, reference checks, candidate interviews, and job offers. </p>
   <p>HR Essentia also manages the performance of your employees by setting performance goals, providing feedback and coaching, and conducting performance evaluations. These services are designed to enhance employee performance with specific outcomes such as improved productivity and employee engagement. </p>
  
    </div>
    <div className="order-2 order-lg-2 col-xxl-6 col-xl-l col-lg-6  col-md-12 col-sm-12 col-12">
    <div className="advance-tab-button advance-tab-button-1">
    <TabList>
      <Tab>
      <div className='hrtabtile'>
      <i class="fa fa-users" aria-hidden="true"></i>
      <br />
      Compensation & Benefits 
      </div></Tab>
      <Tab>
      <div className='hrtabtile'>
      <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
      <br />
      Training & Development
            </div></Tab>
      <Tab><div className='hrtabtile'>
      <i class="fa fa-lightbulb-o" aria-hidden="true"></i>
      <br />
      Recruitment and Hiring
            </div></Tab>
    </TabList>
    </div>
    <TabPanel>
    <img src={Compensation} className="tabhrteam" alt='' />
    <div className='hrtabcontent'>
      <h5>Compensation and benefits </h5>
      <p>We ensure that the employees are fairly compensated and provided with appropriate benefits. </p>
</div>
    </TabPanel>
    <TabPanel>
    <img src={deveolp} className="tabhrteam" alt='' />
    <div className='hrtabcontent'>
      <h5>Training and development </h5>
      <p>We help employees improve their skills and knowledge with increased productivity and job satisfaction.</p>
</div>
      
    </TabPanel>
    <TabPanel>
    <img src={onboardingserv} className="tabhrteam" alt='' />
    <div className='hrtabcontent'>
      <h5>Recruitment and hiring  </h5>
      <p>We help you fill open positions with qualified candidates. </p>
</div>
    </TabPanel>
    </div>
    </div>
    </Tabs>
    </div>
</section>
   <section className="alsersections8" id='Counseling'>
            <div className="container">
            <div className="row">
        <div className="col-xxl-4 col-xl-4 col-md-6 col-lg-4 col-12">
        <div className="cacilds">
        <h6 className="How">How We Work</h6>
        <h2>Key Points</h2>
   <ul className="timeline">
  <li className="timeline-inverted">
    <div className="timeline-badge" id="firstbadge">01</div>
    <div className="timeline-panel">
      <div className="flex-container flex-around">
        <div className="timeline-heading">
          <h5 className="timeline-title">Follow-up</h5>
         <p>We understand that employee counseling is not a one-time event that's why our professionals keep following up with your employees.</p>
        </div>
              </div>
    </div>
  </li>
  <li className="timeline-inverted">
    <div className="timeline-badge" id="secondbadge">02</div>
    <div className="timeline-panel">
      <div className="flex-container flex-around">
        <div className="timeline-heading">
        <h5 className="timeline-title">Empowerment</h5>
        <p> HR professionals help employees to identify their strengths and resources and encourage them to make positive changes.  </p>
        </div>
         </div>
    </div>
  </li>
  <li className="timeline-inverted">
    <div className="timeline-badge" id="firstbadge">03</div>
    <div className="timeline-panel">
      <div className="flex-container flex-around">
        <div className="timeline-heading">
        <h6 className="timeline-title">Empathy</h6>
          <p> We listen to employees' concerns, showing empathy, and understanding. </p>
        </div>
     </div>
    </div>
  </li>

</ul>
</div>
        </div>
        <div className="col-xxl-8 col-xl-8 col-md-6 col-lg-8 col-12" id="perfect">
        <div className="hiringcontent">
            <h2>Counseling of <span className='mycolor'> Employees</span></h2>
       <p>At HR Essentia, we ensure to be with you whenever your employee has any conflict or issue; we will resolve the following concern by conducting counseling sessions because we understand and value our client's valuable employees. </p>
       <p>We listen to your employees actively and understand that their concerns must be kept confidential to build the trust of your employees and ensure that employees feel comfortable sharing their concerns. </p>
       <p>Investing in employee counseling is a win-win! Not only does it help staff members improve their work-life balance and manage stress, but it also addresses personal issues that may be affecting their performance. Plus, we can help identify and handle workplace issues like conflicts and communication breakdowns, ensuring a harmonious and productive work environment. </p>
<ul class="lab-ul">
        <li><i class="icofont-tick-mark"></i>Confidentiality</li>
        <li><i class="icofont-tick-mark"></i>Active listening </li>
        <li><i class="icofont-tick-mark"></i>Root cause identification</li>
       
        </ul>
        </div>
        </div>
        </div>
      </div>
        </section>
        <div id='Compliance'></div>
 <section className='hiringserivce9 desk'>
   <div className='container'>
    <Tabs>
    <div className="row row--30">
      <div className="order-2 order-lg-1 col-xxl-6 col-xl-l col-lg-6 col-md-12 col-sm-12 col-12 mt_md--30 mt_sm--30" id='headintb'>
    <h2>Posh <span className='mycolor'> Compliance</span></h2>
 <p>When hiring HR Essentia, you need not consider women's workplace safety. We understand that female staff seeks proper safety at the workplace or even after shift hours. That's why we provide end-to-end solutions to make your Company's environment the safest for women, whether during the day or at night. </p>
<p>At HR Essentia, we take full responsibility for setting and enforcing safety parameters to comply with POSH regulations. Plus, our professional approach ensures to provide your female staff the peace of mind they deserve- trust HR Essentia for a safe and secure workplace. </p>
<p>With our POSH compliance services, you can trust us to take care of all aspects of women's safety in the workplace. We offer customized solutions tailored to your business requirements, ensuring women employees' safety. Our POSH service portfolio includes the following:</p>
    <div className="advance-tab-button advance-tab-button-1">
    <TabList>
      <Tab>
      <div className='hrtabtile'>
      <i class="fa fa-users" aria-hidden="true"></i>
      <br />
      Policy <br />Development
      </div></Tab>
      <Tab>
      <div className='hrtabtile'>
      <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
      <br />
      Awareness Posters<br /> & Notices
            </div></Tab>
      <Tab><div className='hrtabtile'>
      <i class="fa fa-lightbulb-o" aria-hidden="true"></i>
      <br />
      Complaint <br />Mechanism 
            </div></Tab>
    </TabList>
    </div>
    </div>
    <div className="order-1 order-lg-2 col-xxl-6 col-xl-l col-lg-6  col-md-12 col-sm-12 col-12" id='headintb'>
    <TabPanel>
    <img src={audit} className="tabhrteam" alt='' />
    <div className='hrtabcontent'>
      <h5>Policy Development</h5>
      <p>We develop and implement a clear and comprehensive sexual harassment policy that outlines all essential aspects.</p>
</div>
    </TabPanel>
    <TabPanel>
    <img src={onboardingserv} className="tabhrteam" alt='' />
    <div className='hrtabcontent'>
      <h5>Awareness Posters & Notices</h5>
      <p>Poster at the workplace is set which outlines the information constituting sexual harassment, how to report it, etc. 
</p>
</div>
    </TabPanel>
    <TabPanel>
    <img src={complaint} className="tabhrteam" alt='' />
    <div className='hrtabcontent'>
      <h5>Complaint Mechanism  </h5>
      <p>We set up a complaint mechanism for employees to report incidents of sexual harassment. 
</p>
</div>
    </TabPanel>
    </div>
    </div>
    </Tabs>
    </div>
</section>

<section className='hiringserivce9 mobile'>
   <div className='container'>
    <Tabs>
    <div className="row row--30">
      <div className="order-2 order-lg-1 col-xxl-6 col-xl-l col-lg-6 col-md-12 col-sm-12 col-12 mt_md--30 mt_sm--30" id='headintb'>
    <h2>Posh <span className='mycolor'> Compliance</span></h2>
 <p>When hiring HR Essentia, you need not consider women's workplace safety. We understand that female staff seeks proper safety at the workplace or even after shift hours. That's why we provide end-to-end solutions to make your Company's environment the safest for women, whether during the day or at night. </p>
 <p>We develop a clear and comprehensive policy on the prevention of sexual harassment in the workplace, which includes guidelines on reporting, investigation, and resolution of complaints. </p>
 <p>Some preventive measures are implemented, like sensitization and awareness training programs for employees, creating a safe and supportive work environment, and enforcing a zero-tolerance policy towards sexual harassment. </p>
    
    </div>
    <div className="order-1 order-lg-2 col-xxl-6 col-xl-l col-lg-6  col-md-12 col-sm-12 col-12" id='headintb'>
    <div className="advance-tab-button advance-tab-button-1">
    <TabList>
      <Tab>
      <div className='hrtabtile'>
      <i class="fa fa-users" aria-hidden="true"></i>
      <br />
      Compliance <br />Audits
      </div></Tab>
      <Tab>
      <div className='hrtabtile'>
      <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
      <br />
      Training & <br /> Awareness
            </div></Tab>
      <Tab><div className='hrtabtile'>
      <i class="fa fa-lightbulb-o" aria-hidden="true"></i>
      <br />
      Complaints & <br /> Redressal 
            </div></Tab>
    </TabList>
    </div>
    <TabPanel>
    <img src={audit} className="tabhrteam" alt='' />
    <div className='hrtabcontent'>
      <h5>Compliance Audits </h5>
      <p>We conduct regular audits to ensure compliance with the POSH Act. </p>
</div>
    </TabPanel>
    <TabPanel>
    <img src={onboardingserv} className="tabhrteam" alt='' />
    <div className='hrtabcontent'>
      <h5>Training and Awareness</h5>
      <p>We provide regular training to employees and management on the POSH Act.
</p>
</div>
    </TabPanel>
    <TabPanel>
    <img src={complaint} className="tabhrteam" alt='' />
    <div className='hrtabcontent'>
      <h5>Complaints and redressal </h5>
      <p>We set up a complaint mechanism for employees to report incidents of sexual harassment. 
</p>
</div>
    </TabPanel>
    </div>
    </div>
    </Tabs>
    </div>
</section>

   </>
    );
}
 
export default Hiringtabs;