
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';


const sponsorList = [
    {
        imgUrl: 'assets/images/sponsor/logo2.png',
        imgAlt: 'sponsor',
    
    },
    {
        imgUrl: 'assets/images/sponsor/logo4.png',
        imgAlt: 'sponsor',
    },
    {
        imgUrl: 'assets/images/sponsor/logo5.png',
        imgAlt: 'sponsor',
    },
    {
        imgUrl: 'assets/images/sponsor/logo6.png',
        imgAlt: 'sponsor',
    },
    {
        imgUrl: 'assets/images/sponsor/logo7.png',
        imgAlt: 'sponsor',
    },
    {
        imgUrl: 'assets/images/sponsor/logo8.png',
        imgAlt: 'sponsor',
    },
    {
        imgUrl: 'assets/images/sponsor/logo9.png',
        imgAlt: 'sponsor',
    },
    {
        imgUrl: 'assets/images/sponsor/logo10.png',
        imgAlt: 'sponsor',
    },
    {
        imgUrl: 'assets/images/sponsor/logo11.png',
        imgAlt: 'sponsor',
    },
    {
        imgUrl: 'assets/images/sponsor/logo12.png',
        imgAlt: 'sponsor',
    },
    {
        imgUrl: 'assets/images/sponsor/logo13.png',
        imgAlt: 'sponsor',
    },
    {
        imgUrl: 'assets/images/sponsor/logo14.png',
        imgAlt: 'sponsor',
    },
    {
        imgUrl: 'assets/images/sponsor/logo15.png',
        imgAlt: 'sponsor',
    },
    {
        imgUrl: 'assets/images/sponsor/logo16.png',
        imgAlt: 'sponsor',
    },
    {
        imgUrl: 'assets/images/sponsor/logo17.png',
        imgAlt: 'sponsor',
    },
    {
        imgUrl: 'assets/images/sponsor/logo18.png',
        imgAlt: 'sponsor',
    },
    {
        imgUrl: 'assets/images/sponsor/logo19.png',
        imgAlt: 'sponsor',
    },
    {
        imgUrl: 'assets/images/sponsor/logo20.png',
        imgAlt: 'sponsor',
    },
    {
        imgUrl: 'assets/images/sponsor/logo21.png',
        imgAlt: 'sponsor',
    },
]


const Sponsor = () => {
    return (
        <div className="sponsor-section ">
            <div className="container">
                <div className="section-wrapper">
                    <div className="sponsor-slider">
                        <Swiper
                            spaceBetween={20}
                            slidesPerView={2}
                            loop={'true'}
                            autoplay={{
                                delay: 8000,
                                disableOnInteraction: false,
                            }}
                            modules={[Autoplay]}
                            breakpoints={{
                                0: {
                                    width: 0,
                                    slidesPerView: 2,
                                },
                                768: {
                                    width: 768,
                                    slidesPerView: 4,
                                },
                                1200: {
                                    width: 1200,
                                    slidesPerView: 4.5,
                                },
                            }}
                        >
                            {sponsorList.map((val, i) => (
                                <SwiperSlide key={i}>
                                    <div className="sponsor-iten">
                                        <div className="sponsor-thumb">
                                            <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Sponsor;