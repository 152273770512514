import React from 'react';
import onboardingserv from "../../src/assets/images/onboardingserv.webp";
import Footer1 from '../component/layout/footer';
import Header from '../component/layout/header';

const AllBlogs = () => {
    return (
       <>
       <Header />
          <section className="abtbnner">
        <div className="container">
            <div className="row">
                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                    <h1>Our<span className='mycolor'> Blogs</span></h1>
                    <p className="Are">Are you looking for outsourcing solutions and release all the burden your business is bearing daily?<br />
Contact us and let’s have a free session.</p>
                </div>
            </div>
        </div>
        </section>
                       
  <section className='blogsection'>
   <div className='container'>
 <div className='row'>
      <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12'>
                            <div class="post-inner">
                            <div className="dlab-post-media dlab-img-effect ">
                            <a href="/howHROutsourcingCanHelpSmallBusinessesCompetewithLargerCorporations">
                            <img src={onboardingserv} data-tilt alt="" className='blogimg'/>
                            </a> 
                            </div>
                            <div class="dlab-info p-a20 border-1">
                            <div class="dlab-post-meta"><ul class="d-flex align-items-center">
                                <li class="post-date"> <i class="fa fa-calendar"></i><strong>7 March</strong> <span> 2023</span> </li>
                                <li class="post-author"><i class="fa fa-user"></i>By <span>Mark Peter </span></li>
                                 </ul>
                                </div>
                             <div class="dlab-post-title ">
         <h4><a href="/howHROutsourcingCanHelpSmallBusinessesCompetewithLargerCorporations">How to Be A Successful Trucking Fleet Dispatcher?</a></h4></div>
                            
                                <div class="dlab-post-text"><p>All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.</p>
                                </div>
                                <div class="dlab-post-readmore blog-share">
                                    <a title="READ MORE" rel="bookmark" class="site-button outline outline-1" href="howHROutsourcingCanHelpSmallBusinessesCompetewithLargerCorporations">Read More<i class="fa fa-long-arrow-right ml-1"></i></a></div>
                            </div>
                            
                            </div>
       </div>
       <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12'>
                            <div class="post-inner">
                            <div className="dlab-post-media dlab-img-effect ">
                            <a href="/howHROutsourcingCanHelpSmallBusinessesCompetewithLargerCorporations">
                            <img src={onboardingserv} data-tilt alt="" className='blogimg'/>
                            </a> 
                            </div>
                            <div class="dlab-info p-a20 border-1">
                            <div class="dlab-post-meta"><ul class="d-flex align-items-center">
                                <li class="post-date"> <i class="fa fa-calendar"></i><strong>7 March</strong> <span> 2023</span> </li>
                                <li class="post-author"><i class="fa fa-user"></i>By <span>Mark Peter </span></li>
                                 </ul>
                                </div>
                             <div class="dlab-post-title ">
         <h4><a href="/howHROutsourcingCanHelpSmallBusinessesCompetewithLargerCorporations">How to Be A Successful Trucking Fleet Dispatcher?</a></h4></div>
                            
                                <div class="dlab-post-text"><p>All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.</p>
                                </div>
                                <div class="dlab-post-readmore blog-share">
                                    <a title="READ MORE" rel="bookmark" class="site-button outline outline-1" href="howHROutsourcingCanHelpSmallBusinessesCompetewithLargerCorporations">Read More<i class="fa fa-long-arrow-right ml-1"></i></a></div>
                            </div>
                            
                            </div>
       </div>
       <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12'>
                            <div class="post-inner">
                            <div className="dlab-post-media dlab-img-effect ">
                            <a href="/howHROutsourcingCanHelpSmallBusinessesCompetewithLargerCorporations">
                            <img src={onboardingserv} data-tilt alt="" className='blogimg'/>
                            </a> 
                            </div>
                            <div class="dlab-info p-a20 border-1">
                            <div class="dlab-post-meta"><ul class="d-flex align-items-center">
                                <li class="post-date"> <i class="fa fa-calendar"></i><strong>7 March</strong> <span> 2023</span> </li>
                                <li class="post-author"><i class="fa fa-user"></i>By <span>Mark Peter </span></li>
                                 </ul>
                                </div>
                             <div class="dlab-post-title ">
         <h4><a href="/howHROutsourcingCanHelpSmallBusinessesCompetewithLargerCorporations">How to Be A Successful Trucking Fleet Dispatcher?</a></h4></div>
                            
                                <div class="dlab-post-text"><p>All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.</p>
                                </div>
                                <div class="dlab-post-readmore blog-share">
                                    <a title="READ MORE" rel="bookmark" class="site-button outline outline-1" href="howHROutsourcingCanHelpSmallBusinessesCompetewithLargerCorporations">Read More<i class="fa fa-long-arrow-right ml-1"></i></a></div>
                            </div>
                            
                            </div>
       </div>     
   
  </div>
   </div>
 </section>
                <div className="page-content bg-white">
               

         
                </div>
             <Footer1 />
       </>
    );
}
 

export default AllBlogs;