
import {BrowserRouter, Routes, Route } from "react-router-dom";
import 'swiper/css';


import ScrollToTop from "./component/layout/ScrollToTop";
import ErrorPage from "./page/404";
import BlogPage from "./page/blog";
import BlogPageTwo from "./page/blog-2";
import BlogPageThree from "./page/blog-3";
import BlogSingle from "./page/blog-single";
import CartPage from "./page/cart-page";
import ContactPage from "./page/contact";

import ForgetPass from "./page/forgetpass";
import Home from "./page/home";

import InstructorPage from "./page/instructor";

import SearchNone from "./page/search-none";
import SearchPage from "./page/search-page";

import AboutUs from "./page/aboutus";
import Services from "./page/services";
import ContactUs from "./page/contactus";
import Privacypolicy from "./page/privacypolicy";
import Termsandconditions from "./page/termsandconditions";
import Disclaimer from "./page/disclaimer";
import AllBlogs from "./page/allBlogs";
import HowHROutsourcingCanHelpSmallBusinessesCompetewithLargerCorporations from "./page/howHROutsourcingCanHelpSmallBusinessesCompetewithLargerCorporations";
import Thankyou from "./page/thankyou";

function App() {
	return (
		<BrowserRouter>
			<ScrollToTop />
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="thankyou" element={<Thankyou />} />
				<Route path="allBlogs" element={<AllBlogs />} />
				<Route path="howHROutsourcingCanHelpSmallBusinessesCompetewithLargerCorporations" element={<HowHROutsourcingCanHelpSmallBusinessesCompetewithLargerCorporations />} />
				<Route path="disclaimer" element={<Disclaimer />} />
				<Route path="termsandconditions" element={<Termsandconditions />} />
				<Route path="privacypolicy" element={<Privacypolicy />} />
				<Route path="services" element={<Services />} />
				<Route path="contactus" element={<ContactUs />} />
				<Route path="aboutus" element={<AboutUs />} />
				
				<Route path="blog" element={<BlogPage />} />
				<Route path="blog-2" element={<BlogPageTwo />} />
				<Route path="blog-3" element={<BlogPageThree />} />
				<Route path="blog-single" element={<BlogSingle />} />
				<Route path="instructor" element={<InstructorPage />} />
			
	
				<Route path="cart-page" element={<CartPage />} />
				<Route path="search-page" element={<SearchPage />} />
				<Route path="search-none" element={<SearchNone />} />
				<Route path="contact" element={<ContactPage />} />
		
			
				<Route path="forgetpass" element={<ForgetPass />} />
				<Route path="*" element={<ErrorPage />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
