import { Fragment } from "react";
import { Link } from "react-router-dom";
import FooterThree from "../component/layout/footer-3";
import Header from "../component/layout/header";
import Pagination from "../component/sidebar/pagination";
import {Helmet} from "react-helmet";

const blogList = [
    {
        imgUrl: 'assets/images/blog/01.jpg',
        imgAlt: 'Blog Thumb',
        title: 'Business Ueporting Rouncil Them Could Plan.',
        desc: 'Pluoresnts customize prancing apcentered customer service anding ands asing straelg Interacvely cordinate performe',
        commentCount: '3',
        btnText: 'Read More',
        metaList: [
          
            {
                iconName: 'icofont-calendar',
                text: 'Jun 05,2023',
            },
            {
                iconName: 'icofont-ui-user',
                text: 'Rajib Raj',
            },
        ],
    },
    {
        imgUrl: 'assets/images/blog/02.jpg',
        imgAlt: 'Blog Thumb',
        title: 'Financial Reporting Qouncil What Could More.',
        desc: 'Pluoresnts customize prancing apcentered customer service anding ands asing straelg Interacvely cordinate performe',
        commentCount: '3',
        btnText: 'Read More',
        metaList: [
           
            {
                iconName: 'icofont-calendar',
                text: 'Jun 05,2022',
            },
            {
                iconName: 'icofont-ui-user',
                text: 'Rajib Raj',
            },
        ],
    },
    {
        imgUrl: 'assets/images/blog/03.jpg',
        imgAlt: 'Blog Thumb',
        title: 'Consulting Reporting Qounc Arei Could More.',
        desc: 'Pluoresnts customize prancing apcentered customer service anding ands asing straelg Interacvely cordinate performe',
        commentCount: '3',
        btnText: 'Read More',
        metaList: [
          
            {
                iconName: 'icofont-calendar',
                text: 'Jun 05,2022',
            },
            {
                iconName: 'icofont-ui-user',
                text: 'Rajib Raj',
            },
        ],
    },
    
]



const BlogPage = () => {
    return ( 
        <Fragment>
            <Header />
            <Helmet>
<title>Freelance Human Resources Consultant in Mohali, Chandigarh</title>
<meta name="description" content="HR Essentia is the Best Human Resources Outsourcing Services in Mohali, Chandigarh. Our blog section offers an ultimate guide through HR Outsourcing process and will help you fill the gaps in your organization." />
</Helmet>
            <section className="abtbnner">
        <div className="container">
            <div className="row">
                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                    <h1>Our<span className='mycolor'> Blogs</span></h1>
                    <p className="Are">Are you looking for outsourcing solutions and release all the burden your business is bearing daily?<br />
Contact us and let’s have a free session.</p>
                </div>
            </div>
        </div>
        </section>
            {/* <PageHeader title={'Our Blog Posts'} curPage={'Blog'} /> */}
            <div className="blog-section padding-tb">
                <div className="container">
                    <div className="section-wrapper">
                        <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 justify-content-center g-4">
                            {blogList.map((val, i) => (
                                <div className="col" key={i}>
                                    <div className="post-item">
                                        <div className="post-inner">
                                            <div className="post-thumb">
                                                <Link to="/blog-single"><img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} className="blgimg" /></Link>
                                            </div>
                                            <div className="post-content">
                                            <div className="meta-post">
                                                    <ul className="lab-ul">
                                                        {val.metaList.map((val, i) => (
                                                            <li key={i}><i className={val.iconName}></i>{val.text}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                <Link to="/blog-single"><h4>{val.title}</h4></Link>
                                               
                                                <p>{val.desc}</p>
                                            </div>
                                            <div className="post-footer">
                                                <div className="pf-left">
                                                    <Link to="/blog-single" className="lab-btn-text1">{val.btnText} <i className="icofont-external-link"></i></Link>
                                                </div>
                                                <div className="pf-right">
                                                    <i className="icofont-comment"></i>
                                                    <span className="comment-count">{val.commentCount}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <Pagination />
                    </div>
                </div>
            </div>
     <FooterThree />
        </Fragment>
    );
}

export default BlogPage;