import { Fragment } from "react";
import Footer1 from "../component/layout/footer";
import FooterThree from "../component/layout/footer-3";
import Header from "../component/layout/header";
import Contactsections from "../component/section/contactsections";

import {Helmet} from "react-helmet";


const Contactus = () => {
    return (
        <Fragment>
            <Header />
<Helmet>
<title>Best Online Payroll Services in Mohali, Chandigarh</title>
<meta name="description" content="Reach out to the best Payroll outsourcing company in Tricity, HR Essentia. A team of professionals who can fluently manage all the payroll operations of your firm." />
<link rel="canonical" href="https://hressentia.com/contactus" />
</Helmet>
            <Contactsections />
            <Footer1 />
             </Fragment>
    );
}
 
export default Contactus;