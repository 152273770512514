
const FeatureRegister = () => {
    return (
        <div className="feature-register">
            <h3>We’d Love To Talk About <span className='mycolor'>Your Goals</span></h3>
            <p>Fill out this form with your company details and one of our experts will contact you.</p>
            <form action="contact.php"
                     method="post"
                    encType="multipart/form-data"
                    id="myForm">
                  <div className="row">
                    <div className="col-12" id="myformfield">
                    <input type="text" name="name" placeholder="Your Name" className="reg-input" required />
                    </div>
                    <div className="col-12" id="myformfield">
                    <input type="text" name="phone" placeholder="Your Phone" className="reg-input" required />
                    </div>
                  </div>
                  <div className="row">
                  <div className="col-12" id="myformfield">
                  <input type="email" name="email" placeholder="Your Email" className="reg-input" required />
                    </div>
                    <div className="col-12" id="myformfield">
                    <input type="text" name="location" placeholder="Your Location" className="reg-input" required />
                     </div>
                     <div className="col-12" id="myformfield">
                     <textarea id="message" 
                  title="Message" 
                  cols="30" 
                  name='message'
                  rows="3" 
                  maxlength="500" 
                  placeholder="Enter Message" 
                  required />
                  </div>
                     </div>
                     <button className="lab-btn" type="submit"><span>Submit</span></button>
                 
            </form>
        </div>
    );
}
 
export default FeatureRegister;