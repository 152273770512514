import React from 'react';
import hrlogo from "../../assets/images/hrlogo.png";


const Footer1 = () => {
    return (
        <>
        <section className='second footer'>
                <div className='container'>
                <div className='row' id='challenges'>
                <div className='col-xxl-l col-xl-6 col-md-6 col-lg- col-12'>
                <h5>Our Story</h5>
                    <p className='autopilot'>Empowering Businesses with Innovative and Seamless Payroll  solutions</p>
                </div>
                    <div className='col-xxl-l col-xl-6 col-md-6 col-lg- col-12'>
                    <form 
 className="wow fadeInUp"
              data-wow-delay="0.12s"
              data-wow-duration="1s"
              style={{
                visibility: "visible",
                animationDuration: "1s",
                animationDelay: "0.12s",
                animationName: "fadeInUp"
              }}
              action="newsletter.php"
                     method="post"
                    encType="multipart/form-data"
                    id="myForm">
  <input className='bnnrfrm' name='email' type="email" placeholder="Enter Your Email Address" required />
  <button type="submit">Get A Quote</button>
  </form>
                    </div>
                </div>
                    <div className='row'>
                        <div className='col-xxl-3 col-xl-3 col-md-6'>
                        <a href='/'>
                        <img src={hrlogo} className="footerlogo" alt='' />
                        </a>
                       <div className='cont'>
                       <p> Revolutionizing HR and payroll <br />management with innovative and<br />flexible solutions with years of industry experience</p>
                       </div>
                   </div>
                        <div className='col-xxl-3 col-xl-3 col-md-6' id='footercol'>
                        <h4>Useful Links</h4>
                        <ul className='Solutions'>
                            <li><a href='/'> Home</a></li>
                            <li><a href='/aboutus'> About Us</a></li>
                            <li><a href='/services'> Our Services</a></li>
                            {/* <li><a href='/allBlogs'>All Blog</a></li> */}
                             </ul>
                        </div>
                        <div className='col-xxl-3 col-xl-3 col-md-6' id='footercol'>
                        <h4>Important Links</h4>
                      <ul className='Solutions'>
                      <li><a href='/privacypolicy'> Privacy Policy</a></li>
                      <li><a href='/termsandconditions'> Terms & Conditions</a></li>
                       <li><a href='/contactus'> Contact</a></li>
                    </ul>
                         </div>
                        <div className='col-xxl-3 col-xl-3 col-md-6' id='footercol'>
                        <h4>Contact Us</h4>
                        <ul className='Solutions'>
                        <p className='address'><span><i class="icofont-location-pin"></i></span>KMG Towers, Sector 67, Sahibzada Ajit Singh Nagar, Punjab</p>
                        <p className='address'><span><i class="icofont-phone"></i></span><a href='tel:+919041888938'>+91 90418 88938</a> </p>
                       <p className='address'><span><i class="icofont-envelope"></i></span><a href='mailto:reach@hressentia.com'>reach@hressentia.com</a></p>
                
                        </ul>
                        </div>
                    </div>      
                </div>
            </section>
      
            {/* <div className="whatsapp">
          < a href="https://api.whatsapp.com/send?phone=919876769096&amp;text=Hello! I am Looking for Digital Marketing Service">
          <i class="icofont-whatsapp"></i></a>
        </div> */}
             <div className="copyright-area copyright-style-one no-border">
                    <div className="container">
                        <div className="row">
                         <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-8 col-sm-12 col-12">
                                <div className="copyright-right">
                               <p className="copyright-text"> © Copyright 2023. All Rights Reserved by <a className='Copyright' href="/">Hressentia.com</a></p>
                                </div>
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-4 col-sm-12 col-12">
                            <ul class="lab-ul social-icons" id='Reserved'>
                <li><a href="https://www.facebook.com/HREssentia" class="oursocial"><i class="icofont-facebook"></i></a></li>
                  <li><a href="https://www.linkedin.com/company/hressentia/" class="oursocial"><i class="icofont-linkedin"></i></a></li>
                <li><a href="https://instagram.com/hressentia?igshid=YmMyMTA2M2Y=" class="oursocial"><i class="icofont-instagram"></i></a></li>
                             </ul>
                            </div>
                        </div>
                    </div>
                </div>


                  </>
    )
}

export default Footer1;
